export const RECORD_TYPE = {
  APPLICATION: 'application',
  APPLICANT: 'applicant',
  APPLICANT_MANAGEMENT: 'applicant_management',
  COMPANY: 'company',
  OFFER: 'offer',
  COMPANY_CONTACT: 'companyContact',
  SUPPORT_CONTACT: 'supportContact',
  ORGANISATION_PERSON: 'organisationPerson',
  // Search of applicants (=> can search companies)
  SEARCH_FOR_COMPANIES: 'search_for_companies',
  // Search of companies (=> can search applicants)
  SEARCH_FOR_APPLICANTS: 'search_for_applicants',
  PAGE: 'page',
  BOOKMARK_LIST_DEFAULT: 'default',
  BOOKMARK_LIST_COMMENT: 'comment',
  BOOKMARK_TYPE_ALL: 'all',
  CONTACT_TYPE_COMPANY: 'company',
  CONTACT_TYPE_LOCATION: 'institution_location',
};

export const APPLICATION = {
  TYPE_INITIATIVE: 1,
  TYPE_OFFER: 2,
  TYPE_PROFILE: 3,
  TYPE_TEMPLATE: 9,

  STATUS_NEW: 1,
  STATUS_SENT: 2,
  STATUS_CANCELLED: 8,
  STATUS_FINISHED: 9,
};
