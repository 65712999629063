var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form",
    {
      staticClass: "login-form-container",
      attrs: { inline: "", id: _vm.id },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.checkUserLogin(_vm.form)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "login-form-close", on: { click: _vm.emitClose } },
        [_c("font-awesome-icon", { attrs: { icon: "times" } })],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "container" }, [
        _c("h6", { staticClass: "login-label" }, [
          _vm._v(
            _vm._s(_vm.$t("components.partials.header.login_form.headline"))
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "form-group",
            class: { "has-error": _vm.errors.username },
          },
          [
            _c("b-form-input", {
              attrs: {
                id: _vm.context + "login",
                placeholder: _vm.$t(
                  "components.partials.header.login_form.email"
                ),
                type: "email",
                required: "",
                trim: "",
              },
              model: {
                value: _vm.form.username,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "username", $$v)
                },
                expression: "form.username",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "invalid-feedback" }, [
              _vm._v(
                _vm._s(
                  _vm.$t("components.partials.header.login_form.email.feedback")
                )
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "form-group",
            class: { "has-error": _vm.errors.password },
          },
          [
            _c("ba-form-password", {
              attrs: {
                id: _vm.context + "password",
                placeholder: _vm.$t(
                  "components.partials.header.login_form.password"
                ),
                required: "",
              },
              model: {
                value: _vm.form.password,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "password", $$v)
                },
                expression: "form.password",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "invalid-feedback" }, [
              _vm.errors.passwordInvalid
                ? _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "components.partials.header.login_form.feedback_general"
                        )
                      )
                    ),
                  ])
                : _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "components.partials.header.login_form.password.feedback"
                        )
                      )
                    ),
                  ]),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "action-container" },
          [
            _c(
              "b-button",
              {
                staticClass: "login-button",
                attrs: { variant: "primary", type: "submit" },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t(
                        "components.partials.header.login_form.action.login"
                      )
                    ) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "b-link",
              {
                staticClass: "lost-password",
                attrs: {
                  to: _vm.appLocalizeRoute({ name: "password-request" }),
                },
                on: { click: _vm.emitClose },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t(
                        "components.partials.header.login_form.action.forgot_password"
                      )
                    ) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "b-link",
              {
                staticClass: "signup-link",
                attrs: { to: _vm.appLocalizeRoute({ name: "register" }) },
                on: { click: _vm.emitClose },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t(
                        "components.partials.header.login_form.action.sign_up"
                      )
                    ) +
                    "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }