import Http from '@/services/http';

if (Http.isMockingEnabled()) {
  const mock = Http.mockAdapter;

  mock.onGet('/statistics/usage').reply(function (request) {
    return [200, {
      applicants: 422,
      companies: 534,
      mathx3: 40123,
    }];
  });
}
