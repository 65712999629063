import Http from '@/services/http';

export function getAccount () {
  return Http.get('/account');
}

export function getUserInfo (id) {
  return Http.get('/userinfo/' + id);
}

export function getAccountDashboard () {
  return Http.get('/account/dashboard');
}

export function updateAccount (account) {
  return Http.post('/account', account);
}

export function requestPassword (email) {
  return Http.post('/account/request-password', { email: email });
}

export function resetPassword (token, password) {
  return Http.post('/account/reset-password', { token: token, password: password });
}

export function registerAccount (account) {
  return Http.put('/account', account);
}

export function registerConfirm (token) {
  return Http.post('/account/confirm', { token: token });
}

export function deleteAccount () {
  return Http.delete('/account');
}
