import { render, staticRenderFns } from "./BaTopbarMenu.vue?vue&type=template&id=8585b7de"
import script from "./BaTopbarMenu.vue?vue&type=script&lang=js"
export * from "./BaTopbarMenu.vue?vue&type=script&lang=js"
import style0 from "./BaTopbarMenu.vue?vue&type=style&index=0&id=8585b7de&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/translations/navigation.i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fgerthammes%2Fdev%2Fcampus-match%2Fdhbw-mobilitaetsdatenbank_campus-match-frontend%2Fsrc%2Fcomponents%2Fpartials%2FHeader%2FBaTopbarMenu.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/gerthammes/dev/campus-match/dhbw-mobilitaetsdatenbank_campus-match-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8585b7de')) {
      api.createRecord('8585b7de', component.options)
    } else {
      api.reload('8585b7de', component.options)
    }
    module.hot.accept("./BaTopbarMenu.vue?vue&type=template&id=8585b7de", function () {
      api.rerender('8585b7de', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/partials/Header/BaTopbarMenu.vue"
export default component.exports