import Http from '@/services/http';

export function getOwnOrganisationPersonProfile () {
  return Http.get('/organisation-person-profile');
}

export function updateOwnOrganisationPersonProfile (profile) {
  return Http.post('/organisation-person-profile', profile);
}

export function setOwnOrganisationPersonProfileImage (image, type) {
  const data = new FormData();
  if (image) {
    data.append('image', image, image.name);
    return Http.postMultipart(`/organisation-person-profile/image/${type}`, data);
  }
  return Http.post(`/organisation-person-profile/image/${type}`, { image: null });
}

export function setOwnOrganisationPersonProfileAttachment (file, type) {
  const data = new FormData();
  if (file) {
    data.append('file', file, file.name);
    return Http.postMultipart(`/organisation-person-profile/file/${type}`, data);
  }
  return Http.post(`/organisation-person-profile/file/${type}`, { file: null });
}

// ----------------------------------------------------------------------------

export function getAllPersonsForType (type) {
  return Http.get(`/organisation-persons/${type}`);
}

export function getAllPersonsForOrganisation (type, companyOrLocationId) {
  return Http.get(`/organisation/${type}/${companyOrLocationId}/contacts`);
}

export function getOrganisationPerson (organisationPersonId) {
  return Http.get(`/organisation-person/${organisationPersonId}`);
}

const ImageCache = {};

export function getOrganisationPersonProfileImage (organisationPersonId) {
  return getOrganisationPersonImage(organisationPersonId, 'mainImage');
}

export function getOrganisationPersonImage (organisationPersonId, type) {
  const promise = Http.getBinary(`/organisation-person/${organisationPersonId}/image/${type}`);
  ImageCache[organisationPersonId + '_' + type] = promise;
  return promise;
}
export function getOrganisationPersonImageCached (organisationPersonId, type) {
  return ImageCache[organisationPersonId + '_' + type] ? ImageCache[organisationPersonId + '_' + type] : getOrganisationPersonImage(organisationPersonId, type);
}

export function getOrganisationPersonAttachment (organisationPersonId, type, fileName) {
  return Http.download(`/organisation-person/${organisationPersonId}/file/${type}`, fileName);
}
