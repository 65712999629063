<template>
  <div class="footer">
    <b-container>
      <b-row>
        <b-col order="0" order-lg="0" cols="12" xl="6" class="col-sponsors">
          <h4 class="column-headline logo-headline"><span class="text">{{ $t('components.footer.headline.sponsored_by') }}</span></h4>
          <div class="footer-logos logo-sponsors">
            <a href="https://www.bmbf.de/bmbf/en/home/home_node.html" target="_blank" class="logo" v-if="showEnglishLogos">
              <b-img src="/img/logo_bmbf_en.png" fluid alt="Logo Federal Ministry of Education and Research" class="logo-bmbf"/>
            </a>
            <a href="https://www.bmbf.de/" target="_blank" class="logo" v-else>
              <b-img src="/img/Gefoerdert_vom_BMBF.svg" fluid alt="Logo Bundesministerium für Bildung und Forschung" class="logo-bmbf"/>
            </a>
            <a href="https://www.daad.de/en/" target="_blank" class="logo" v-if="showEnglishLogos">
              <b-img src="/img/daad_logo_suppl_de.svg" fluid alt="DAAD - Deutscher Akademischer Austauschdienst - German Academic Exchange Service" class="logo-daad"/>
            </a>
            <a href="https://www.daad.de/" target="_blank" class="logo" v-else>
              <b-img src="/img/daad_logo_suppl_de.svg" fluid alt="DAAD - Deutscher Akademischer Austauschdienst - German Academic Exchange Service" class="logo-daad"/>
            </a>
          </div>
        </b-col>
        <b-col order="2" order-lg="1" cols="12" lg="6" xl="3">
          <!--
          <div class="block-footer">
            <h4 class="column-headline headline-about"><span class="text">{{ $t('components.footer.headline.about') }} {{ projectName }}</span></h4>
            <p class="footer-text">
              {{ $t('components.footer.content.about') }}
            </p>
          </div>
          -->
          <div class="block-footer">
            <h4 class="column-headline logo-headline"><span class="text">{{ $t('components.footer.headline.platform') }}</span></h4>
            <div class="footer-logos lg-vertical">
              <a href="https://www.dhbw.de/english/home" target="_blank" class="logo" v-if="showEnglishLogos">
                  <b-img :src="customer.logoFooter" alt="DHBW" class="logo-customer" fluid/>
              </a>
              <a :href="customer.website" target="_blank" class="logo" v-else>
                <b-img :src="customer.logoFooter" alt="DHBW" class="logo-customer" fluid/>
              </a>
              <a href="https://www.academic-hero.de/en/produkte/campus-match" target="_blank" class="logo" v-if="showEnglishLogos">
                <b-img src="/img/BA-Logo-Horiz_WHT_Transp.svg" fluid alt="Academic Hero by Brain Appeal"/>
              </a>
              <a href="https://www.academic-hero.de/produkte/campus-match" target="_blank" class="logo" v-else>
                <b-img src="/img/BA-Logo-Horiz_WHT_Transp.svg" fluid alt="Academic Hero von Brain Appeal"/>
              </a>
            </div>
          </div>
        </b-col>
        <b-col order="1" order-lg="2" cols="12" lg="6" xl="3">
          <h4 class="column-headline headline-links"><span class="text">{{ customer.name }} {{ $t('components.footer.headline.links') }}</span></h4>
          <div class="box-links">
            <b-link href="https://www.dhbw.de/startseite" class="link-footer-s" target="_blank">
              <font-awesome-icon class="link-icon" icon="link"/>
              <span class="link-text">www.dhbw.de</span>
            </b-link>
            <b-link class="link-footer-s" v-if="socialLinks.instagram" :href="socialLinks.instagram" target="_blank">
              <font-awesome-icon class="link-icon" :icon="['fab', 'instagram-square']"/>
              <span class="link-text">{{ $t('components.footer.content.links.instagram') }}</span>
            </b-link>
            <b-link class="link-footer-s" v-if="socialLinks.facebook" :href="socialLinks.facebook" target="_blank">
              <font-awesome-icon class="link-icon" :icon="['fab', 'facebook-square']"/>
              <span class="link-text">{{ $t('components.footer.content.links.facebook') }}</span>
            </b-link>
            <b-link class="link-footer-s" v-if="socialLinks.xing" :href="socialLinks.xing" target="_blank">
              <font-awesome-icon class="link-icon" :icon="['fab', 'xing-square']"/>
              <span class="link-text">{{ $t('components.footer.content.links.xing') }}</span>
            </b-link>
            <b-link class="link-footer-s" v-if="socialLinks.youtube" :href="socialLinks.youtube" target="_blank">
              <font-awesome-icon class="link-icon" :icon="['fab', 'youtube-square']"/>
              <span class="link-text">{{ $t('components.footer.content.links.youtube') }}</span>
            </b-link>
            <b-link class="link-footer-s" v-if="socialLinks.linkedin" :href="socialLinks.linkedin" target="_blank">
              <font-awesome-icon class="link-icon" :icon="['fab', 'linkedin']"/>
              <span class="link-text">{{ $t('components.footer.content.links.linkedin') }}</span>
            </b-link>
          </div>
        </b-col>
        <!--
        <b-col order="1" order-lg="3" cols="12" lg="3">
          <h4 class="column-headline headline-contact"><span class="text">{{ $t('components.footer.headline.contact') }}</span></h4>
          <b-link :to="appLocalizeRoute({ name: 'contact' })" class="link-footer-s footer-contact">
            <font-awesome-icon class="link-icon" icon="envelope"/>
            <span class="link-text">{{ $t('components.footer.content.contact') }}</span>
          </b-link>
        </b-col>
        -->
        <b-col order="4" cols="12" lg="7">
          <h4 class="column-headline d-lg-none"><span class="text">{{ $t('components.footer.headline.misc') }}</span></h4>
          <b-nav class="footer-links">
            <b-nav-item v-if="staticPages.imprint" :to="appLocalizeRoute({ name: 'impress' })">{{ $t('components.footer.content.misc.imprint') }}</b-nav-item>
            <b-nav-item v-if="staticPages.privacy" :to="appLocalizeRoute({ name: 'privacy' })">{{ $t('components.footer.content.misc.privacy') }}</b-nav-item>
            <b-nav-item v-if="staticPages.terms" :to="appLocalizeRoute({ name: 'agb' })">{{ $t('components.footer.content.misc.terms') }}</b-nav-item>
            <!--
            <b-nav-item v-if="staticPages.accessibility" :to="appLocalizeRoute({ name: 'accessibility' })">{{ $t('components.footer.content.misc.accessibility') }}</b-nav-item>
            -->
          </b-nav>
        </b-col>
        <b-col class="copyright" order="5" cols="12" lg="5">
          Copyright © {{ copyrightYear }} <span class="copyright-company">{{ customer.name }}</span>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import Config from '@/services/config';
  import { i18n } from 'vue-lang-router';

  export default {
    data: () => ({
      customer: Config.customer,
      projectName: Config.projectName,
      socialLinks: Config.socialLinks,
      staticPages: Config.staticPages,
    }),
    computed: {
      showEnglishLogos: function () {
        return i18n.locale === 'en';
      },
      copyrightYear: function () {
        return new Date().getFullYear();
      },
      websiteDomain: function () {
        return this.customer.website.replace('https://', '')
          .replace('http://', '').replace(/\/+$/, '');
      },
    },
  };
</script>

<style lang="scss">
  @import "../style/_init-vars-functions-mixins";

  .footer {
    color: $ba-text-color-light;
    position: relative;
    width: 100%;
    background: $primary;
    border-radius: 0;
    margin-bottom: 0;

    .col-12 {
      padding-bottom: 15px;
    }

    .col-sponsors {
      margin-bottom: 30px;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 45px;

      .col-12 {
        padding-bottom: 0;
      }
    }
    @include media-breakpoint-up(xl) {

      .col-sponsors {
        margin-bottom: 0;
      }
    }

    a {
      color: $ba-link-color-light;
      @include hover-focus {
        color: #fff;
      }
    }

    .column-headline {
      color: $ba-text-color-light;
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: $ba-font-weight-medium;
      line-height: 1.4375;
      letter-spacing: 1.6px;
      margin-bottom: 15px;
      margin-top: 45px;
      text-align: center;
      position: relative;

      .text {
        display: inline-block;
        min-width: 50%;
        background: $primary;
        position: relative;
        z-index: 1;
        padding: 0 10px;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        height: 1px;
        margin-top: -1px;
        background: #fff;
        opacity: .45;
        top: 50%;
        left: 0;
        width: 100%;
        z-index: 0;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 0;
        margin-bottom: 0;
        min-height: 41px;
        text-align: left;
        &:before,
        &:after {
          display: none;
        }
        .text {
          display: inline-block;
          min-width: auto;
          padding: 0;
        }
      }
    }
    //.logo-headline {
    //  margin-top: 0;
    //  min-height: 0;
    //  margin-bottom: 20px;
    //  text-transform: none;
    //  &:before,
    //  &:after {
    //    display: none;
    //  }
    //  .text {
    //    background: transparent;
    //    text-transform: uppercase;
    //  }
    //  @include media-breakpoint-up(lg) {
    //    margin-bottom: 0;
    //    min-height: 31px;
    //  }
    //}
    .headline-about {
      margin-bottom: 25px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }
    .headline-links {

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        min-height: 50px;
      }
    }
    .headline-contact {
      margin-bottom: 25px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        min-height: 50px;
      }
    }

    .link-footer-s {
      display: block;
      font-size: $ba-font-size-sm;
      font-weight: $ba-font-weight-medium;
      letter-spacing: 0.9px;
      margin-bottom: 35px;
      color: $ba-link-color-light;
      text-align: center;
      &:last-child {
        margin-bottom: 0;
      }

      .link-icon {
        color: #fff;
        width: 19px;
        height: 19px;
        margin-bottom: 5px;
      }
      .link-text {
        color: $ba-text-color-light;
        text-align: center;
        display: block;
        font-size: $ba-font-size-sm;
        font-weight: $ba-font-weight-medium;
        letter-spacing: .9px;
        line-height: 1.166667;
      }
      @include media-breakpoint-up(lg) {
        display: flex;
        margin-bottom: 7px;
        .link-icon {
          margin-right: 12px;
          margin-bottom: 0;
          width: 16px;
          height: 16px;
        }
        .link-text {
          text-align: left;
          line-height: 1.5;
        }
      }
    }

    .footer-text {
      color: #fff;
      font-size: $ba-font-size-sm;
      font-weight: 300;
      line-height: 1.5;
      text-align: center;
      margin-bottom: 29px;

      @media (min-width: 380px) {
        padding-left: 30px;
        padding-right: 30px;
      }

      @include media-breakpoint-up(lg) {
        text-align: left;
        padding: 0;
        margin-bottom: 20px;
      }
    }

    .footer-contact {
      font-size: $ba-font-size-sm;
      font-weight: $ba-font-weight-medium;
      letter-spacing: 0.9px;
      line-height: 1.166667;
      color: $ba-link-color-light;
      text-transform: uppercase;
    }

    .footer-links {
      flex-direction: column;
      position: relative;
      font-size: $ba-font-size-sm;
      line-height: 1.166667;
      letter-spacing: .9px;
      text-align: center;
      .nav-item {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .nav-link {
        font-weight: $ba-font-weight-medium;
        color: $ba-text-color-light;
        padding: 10px;
        text-transform: uppercase;
        @include hover-focus {
          text-decoration: underline;
        }
      }

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        text-align: left;
        //font-size: $ba-font-size-xs;
        line-height: 1.2;
        letter-spacing: 2px;
        margin-bottom: 0;
        padding-top: 42px;
        .nav-item {
          margin-bottom: 0;
          &:first-child {
            .nav-link {
              padding-left: 0;
            }
          }
        }
        .nav-link {
          font-weight: $font-weight-normal;
          text-transform: none;
          padding-bottom: 17px;
        }
      }
    }

    .footer-logos {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      .logo {
        border-right: 1px solid #fafafa;
        flex: 0 0 auto;
        padding: 0 10px;
        &:last-child {
          border-right-width: 0;
        }
      }
      img {
        width: 90px;
        height: auto;
      }
      &.logo-sponsors {
        flex-direction: column;
        .logo {
          background: #fff;
          margin-bottom: 30px;
          border-right-width: 0;
          &:last-child {
            margin-top: 0;
          }
        }
        img {
          width: 100%;
          height: auto;
        }
        @media (min-width: 320px) {
          .logo-bmbf {
            width: 180px;
            height: auto;
          }
          .logo-daad {
            width: 250px;
            height: auto;
          }
        }
        @include media-breakpoint-up(md) {
          flex-direction: row;
          align-items: flex-end;
          .logo {
            margin-right: 30px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      @include media-breakpoint-up(lg) {
        justify-content: flex-start;
        &.lg-vertical {
          align-items: flex-start;
          flex-direction: column;
          .logo {
            border-right-width: 0;
            padding: 0;
            margin-bottom: 15px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .copyright {
      padding: 20px 0 36px 0;
      margin: 0;
      text-align: center;
      //font-size: $ba-font-size-xs;
      font-size: $ba-font-size-sm;
      line-height: 1.2;
      letter-spacing: 2px;
      color: $ba-text-color-light;
      @include media-breakpoint-up(lg) {
        padding-top: 52px;
        padding-bottom: 10px;
        text-align: right;
      }
    }
    .copyright-company {
      text-transform: uppercase;
    }
    @media print {
      background: transparent;
      color: #000;
      padding: 0;
      > .container {
        padding: 0;
        display: block;
        > .row {
          display: block;
          margin: 0;
          > div {
            display: none;
            &.copyright {
              display: block;
              font-size: 1rem;
              color: #000;
              text-align: center;
              max-width: 100%;
              margin: 0;
              padding: 30px 0 10px;
            }
          }
        }
      }
    }
  }
</style>

<i18n src="@/translations/common.i18n.yaml" lang="yaml"/>
