'use strict';

import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import axios from 'axios';

/**
 * STORE MODULES
 */
import account from './modules/account';
import auth from './modules/auth';
import app from './modules/app';
import staticdata from './modules/staticdata';
import search from './modules/search';

Vue.use(Vuex);

const vuexPersist = new VuexPersistence({
  key: 'campusMatchConfiguration',
  storage: window.localStorage,
  modules: ['auth', 'account', 'staticdata', 'search'],
});

const strict = process.env.NODE_ENV === 'development';

export const Store = new Vuex.Store({
  plugins: [vuexPersist.plugin],
  modules: {
    account,
    auth,
    app,
    staticdata,
    search,
  },
  strict,
});

axios.interceptors.response.use(undefined, function (error) {
  if (error.response && error.response.status) {
    // if HTTP Status Code is '401 UNAUTHORIZED', force logout
    if (error.response.status === 401) {
      Store.dispatch('logoutIfAuthenticated');
    } else {
      return error;
    }
  }
});

export default Store;
