<template>
  <language-switcher v-slot="{ links }" tag="ul" class="navbar-nav language-select-container" active-class="active">
    <li class="nav-item language-select" v-for="link in links" :key="link.langIndex" :class="link.activeClass">
      <a :href="link.url" class="nav-link" :data-href="$router.resolve(link.url).href"
         :title="$t('components.common.language_choices.' + link.langIndex)">
        <span class="img-wrap" aria-hidden="true">
          <b-img class="active" :src="'/img/flag_' + link.langIndex + '.svg'" fluid :alt="link.langIndex"/>
          <b-img class="inactive" :src="'/img/flag_' + link.langIndex + '_inactive.svg'" fluid :alt="link.langIndex"/>
        </span>
        <span class="sr-only">{{ link.langIndex }}</span>
      </a>
    </li>
  </language-switcher>
</template>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  .language-select-container {
    --flag-width: 32px;
    position: relative;
    font-size: $ba-font-size-sm;
    letter-spacing: .9px;
    text-transform: uppercase;
    flex-direction: row;
    background-color: $ba-bg-primary;
    max-width: 100%;
    padding: 0 20px 0 0;
    // compensate padding of first nav item
    margin-left: -.5rem;

    > .nav-item {
      text-transform: uppercase;
      .img-wrap {
        position: relative;
        display: block;
      }
      img {
        display: block;
        position: relative;
        width: var(--flag-width);
        height: auto;
        //filter: grayscale(1);
        &.active {
          z-index: 1;
          transition: all .2s linear;
          opacity: 0;
        }
        &.inactive {
          z-index: 0;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
      &.active {
        font-weight: bold;
      }
      &.active,
      .nav-link:hover {
        img {
          &.active {
            opacity: 1;
          }
          //filter: grayscale(0);
        }
      }
      &:first-child {
        position: relative;
        padding-right: 7px;
        margin-right: 5px;

        //&::after {
        //  content: "";
        //  display: block;
        //  width: 2px;
        //  height: 14px;
        //  position: absolute;
        //  right: 0;
        //  top: 50%;
        //  margin-top: -7px;
        //  background: $ba-light-primary;
        //}
      }
    }
    .nav-link {
      padding: .5rem;
    }

    @include media-breakpoint-up(lg) {
      background: transparent;
      display: flex;
      padding: 0;
      margin-left: 30px;
    }
  }
</style>

<i18n src="@/translations/common.i18n.yaml" lang="yaml"/>
<script setup lang="ts">
</script>
