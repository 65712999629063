import Http from '@/services/http';

if (Http.isMockingEnabled()) {
  const mock = Http.mockAdapter;

  mock.onGet('/messaging/threads').reply(function (data) {
    return [200, [
      {
        id: '0',
        archived: false,
        subject: '0 This is an example subject',
        ownParticipants: [
          {
            id: '0',
            name: 'Max Mustermann',
          },
        ],
        otherParticipants: [
          {
            id: '1',
            name: 'Max Mustermann',
          },
        ],
        lastMessageDateTime: '2005-08-15T15:52:01+0000',
      },
      {
        id: '1',
        archived: true,
        subject: '1 This is an example subject',
        ownParticipants: [
          {
            id: '0',
            name: 'Max Mustermann',
          },
        ],
        otherParticipants: [
          {
            id: '2',
            name: 'Max Mustermann',
          },
        ],
        lastMessageDateTime: '2005-08-15T15:52:01+0000',
      },
    ],
    ];
  });

  mock.onGet(/\/messaging\/thread\/[\d]+/).reply(function (request) {
    const threadId = parseInt(request.url.substr(request.url.lastIndexOf('/') + 1));
    return [200, {
      messages: [
        {
          id: '0',
          threadId: threadId,
          senderId: threadId % 2,
          message: 'Lorem ipsum dolor sit amet.',
          sentAt: '2005-08-15T15:52:01+0000',
          readBy: [
            0,
          ],
        },
        {
          id: '1',
          threadId: threadId,
          senderId: (threadId + 1) % 2,
          message: 'Lorem ipsum dolor sit amet.',
          sentAt: '2005-08-15T15:52:01+0000',
          readBy: [
            0,
          ],
        },
        {
          id: '3',
          threadId: threadId,
          senderId: threadId % 2,
          message: 'Lorem ipsum dolor sit amet.',
          sentAt: '2005-08-15T15:52:01+0000',
          readBy: [
            0,
          ],
        },
      ],
      thread: {
        id: threadId,
        archived: (threadId % 2) === 1,
        subject: 'This is an example subject',
        ownParticipants: [
          {
            id: '0',
            name: 'Max Mustermann',
          },
        ],
        otherParticipants: [
          {
            id: '1',
            name: 'Max Mustermann',
          },
        ],
        lastMessageDateTime: '2005-08-15T15:52:01+0000',
      },
    },
    ];
  });

  // End Mocks
}
