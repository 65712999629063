import Http from '@/services/http';

if (Http.isMockingEnabled()) {
  const mock = Http.mockAdapter;

  const LOGIN_PROVIDER = {
    'applicant@testing.brain-appeal.com': {
      access: 'applicant',
      expire: '99999',
    },
    'company@testing.brain-appeal.com': {
      access: 'company',
      expire: '99999',
    },
  };

  mock.onGet('/logout').reply(200, null);

  mock.onPost('/login').reply(function (request) {
    console.info('POST /login');
    const user = JSON.parse(request.data);
    if (LOGIN_PROVIDER[user.username] !== undefined && user.password === 'password') {
      return [200, LOGIN_PROVIDER[user.username]];
    } else {
      return [401, {
        access: '',
        expire: 0,
      }];
    }
  });
}
