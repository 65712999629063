import Http from '@/services/http';

if (Http.isMockingEnabled()) {
  const mock = Http.mockAdapter;

  const offersExample = [
    {
      studyInterest: {
        id: 114,
        name: 'Integrated Engineering',
        course_id: 22,
      },
      years: [],
    },
    {
      studyInterest: {
        id: 75,
        name: 'BWL - Industrie',
        course_id: 28,
      },
      years: {
        2021: null,
        2022: null,
      },
    },
  ];

  const getCompanyTeaser = function (id) {
    return {
      id: id,
      name: 'ACME',
      town: 'Beispielhausen',
      offers: offersExample,
    };
  };

  mock.onGet('/companies').reply(function (request) {
    return [200, [
      getCompanyTeaser(0),
    ]];
  });

  mock.onGet(/\/company\/bookmark\/[\d]+/).reply(function (data) {
    return [200, [
      getCompanyTeaser(0),
      getCompanyTeaser(2),
      getCompanyTeaser(3),
    ]];
  });

  mock.onGet('/company-search/pattern').reply(function (request) {
    return [200, [
      {
        id: '1',
        name: 'Meine Firmen',
      },
    ]];
  });

  mock.onGet(/\/company\/search\/pattern\/[\d]+/).reply(function (request) {
    const searchProfileId = parseInt(request.url.substr(request.url.lastIndexOf('/') + 1));
    return [200,
      {
        id: searchProfileId,
        name: 'Meine Firmen',
        companyName: 'ACME',
        studyCourses: [
          'Digitale Medien',
        ],
        desiredStudyYears: [
          2019, 2020,
        ],
        place: {
          place: 'Mannheim',
          radius: 100,
        },
        excludeBookmarks: Math.random() >= 0.5,
        includeOccupiedCompanies: Math.random() >= 0.5,
      },
    ];
  });

  mock.onGet('/company-bookmarks').reply(function (request) {
    return [200, [
      {
        id: '1',
        name: 'Positiv',
      },
      {
        id: '3',
        name: 'Neutral',
      },
      {
        id: '7',
        name: 'Negativ',
      },
    ]];
  });

  mock.onGet(/\/company\/[\d]+/).reply(function (request) {
    const profileId = parseInt(request.url.substr(request.url.lastIndexOf('/') + 1));
    return [200,

      {
        id: profileId,
        name: 'ACME',
        town: 'Beispielhausen',
        offers: offersExample,
        coordinates: {
          latitude: 49.3193448 + profileId,
          longitude: 8.4306633 + profileId,
        },
        mail: 'foo@bar.email',
        fax: '0800 123 fax 44',
        phone: '0800 123 tel 44',
        www: 'https://www.example.com',
        street: 'straße',
        zip: '01234',
        country: 'Deutschland',
        contacts: [
          {
            title: '',
            firstName: 'Max',
            lastName: 'Mustermann',
            mail: 'foo@bar.email',
            fax: '0800 123 fax 44',
            phone: '0800 123 tel 44',
            www: 'https://www.example.com',
          },
        ],
      },
    ];
  });

  // End Mocks
}
