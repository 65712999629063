import Http from '@/services/http';

export function getAvailableStudyYears () {
  return Http.get('/system/available-study-years');
}

export function getAvailableStudyInterests () {
  return Http.get('/system/available-study-interests');
}

export function getAvailableStudyCourses () {
  return Http.get('/system/available-study-courses');
}

export function getAvailableSubjectAreas () {
  return Http.get('/system/available-subject-areas');
}

export function getAvailableGrades () {
  return Http.get('/system/available-grades');
}

export function getAvailableExpertises () {
  return Http.get('/system/available-expertises');
}

export function getAvailablePlaceRadius () {
  return Http.get('/system/available-search-distances');
}

export function getAvailableQualifications () {
  return Http.get('/system/available-search-qualifications');
}

export function getAvailableSearchDocuments () {
  return Http.get('/system/available-search-documents');
}

export function getAvailableSearchQualificationCountries () {
  return Http.get('/system/available-search-qualification-countries');
}

export function getAvailableSearchNotificationIntervals () {
  return Http.get('/system/available-search-notification-intervals');
}

export function getAvailableDocuments () {
  return Http.get('/system/available-documents');
}

export function getAvailableEmailSubscriptions () {
  return Http.get('/system/available-email-subscriptions');
}

export function getAvailableUniversities () {
  return Http.get('/system_data/high_schools?perPage=2000');
}

export function getAvailableCountries () {
  return Http.get('/system/static-countries');
}
