var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-navbar",
    {
      staticClass: "navbar-top",
      class: {
        "navbar-collapse-show": _vm.mainMenuVisible,
        "navbar-user-logged-in": _vm.isAuthenticated,
        "no-brand": !_vm.headerVisible,
      },
      attrs: { toggleable: "lg" },
    },
    [
      _c(
        "div",
        { staticClass: "navbar-container-lg" },
        [
          _c(
            "b-navbar-brand",
            { class: { "hide-brand": !_vm.headerVisible } },
            [
              _vm.logoUrl
                ? _c(
                    "b-link",
                    { attrs: { href: _vm.logoUrl } },
                    [
                      _c("b-img", {
                        staticClass: "brand-logo",
                        attrs: {
                          src: _vm.brandLogo,
                          alt: _vm.brandAlternative,
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "localized-link",
                    { attrs: { to: _vm.appLocalizeRoute({ name: "home" }) } },
                    [
                      _c("b-img", {
                        staticClass: "brand-logo",
                        attrs: {
                          src: _vm.brandLogo,
                          alt: _vm.brandAlternative,
                        },
                      }),
                    ],
                    1
                  ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isAuthenticated
            ? _c("div", { staticClass: "nav-user" }, [
                _vm.profileImage
                  ? _c(
                      "span",
                      {
                        staticClass: "user-account-info nav-profile-image",
                        attrs: {
                          title:
                            _vm.activeAccount.firstName +
                            " " +
                            _vm.activeAccount.lastName,
                        },
                        on: { click: _vm.toggleNavbarForceOpen },
                      },
                      [
                        _c("span", {
                          staticClass: "ba-image-fill",
                          style: {
                            "background-image": `url(${_vm.profileImage})`,
                          },
                        }),
                      ]
                    )
                  : _c(
                      "span",
                      {
                        staticClass: "user-account-info",
                        attrs: {
                          title:
                            _vm.activeAccount.firstName +
                            " " +
                            _vm.activeAccount.lastName,
                        },
                        on: { click: _vm.toggleNavbarForceOpen },
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "nav-icon",
                          attrs: { size: "2x", icon: "user" },
                        }),
                      ],
                      1
                    ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "user-logout",
                    on: {
                      click: function ($event) {
                        return _vm.logout()
                      },
                    },
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "nav-icon",
                      attrs: { size: "2x", icon: "sign-out-alt" },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v(_vm._s(_vm.$t("action_logout"))),
                    ]),
                  ],
                  1
                ),
              ])
            : _c(
                "b-navbar-toggle",
                {
                  ref: "toggle-user",
                  staticClass: "nav-user",
                  attrs: { target: "nav-collapse-user" },
                },
                [
                  _c(
                    "span",
                    [
                      _c("font-awesome-icon", {
                        staticClass: "nav-icon",
                        attrs: { size: "2x", icon: "user" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
          _vm._v(" "),
          _vm.isAuthenticated
            ? _c(
                "b-navbar-toggle",
                { ref: "toggle-main", attrs: { target: "nav-collapse-main" } },
                [
                  _c("span", { staticClass: "icon-bar top-bar" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "icon-bar middle-bar" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "icon-bar bottom-bar" }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isAuthenticated
            ? _c(
                "b-collapse",
                {
                  attrs: { id: "nav-collapse-main", "is-nav": "" },
                  model: {
                    value: _vm.mainMenuVisible,
                    callback: function ($$v) {
                      _vm.mainMenuVisible = $$v
                    },
                    expression: "mainMenuVisible",
                  },
                },
                [
                  _c("ba-mobile-navbar-menu", {
                    attrs: {
                      "message-count": _vm.messageCount,
                      "profile-image": _vm.profileImage,
                    },
                  }),
                  _vm._v(" "),
                  _vm.headerVisible
                    ? _c(
                        "div",
                        { staticClass: "container nav-container" },
                        [_c("ba-navigation")],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "navbar-top-bar" }, [
                    _c(
                      "div",
                      { staticClass: "container" },
                      [
                        _c("ba-language-select"),
                        _vm._v(" "),
                        _c("ba-topbar-menu", {
                          attrs: {
                            "message-count": _vm.messageCount,
                            "profile-image": _vm.profileImage,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass: "navbar-collapse collapse show",
                  attrs: { id: "nav-collapse-main" },
                },
                [
                  _c("div", { staticClass: "navbar-top-bar no-auth" }, [
                    _c(
                      "div",
                      { staticClass: "container" },
                      [
                        _c("ba-language-select"),
                        _vm._v(" "),
                        _c("ba-topbar-menu", {
                          attrs: { "message-count": 0, "profile-image": false },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
          _vm._v(" "),
          !_vm.isAuthenticated
            ? _c(
                "b-collapse",
                {
                  attrs: { id: "nav-collapse-user", "is-nav": "" },
                  model: {
                    value: _vm.userMenuVisible,
                    callback: function ($$v) {
                      _vm.userMenuVisible = $$v
                    },
                    expression: "userMenuVisible",
                  },
                },
                [
                  _c("ba-login-form", {
                    staticClass: "d-lg-none",
                    attrs: { context: "mobile", id: "nav-login-form-mobile" },
                    on: { close: _vm.toggleNavbarForceClose },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }