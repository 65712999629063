var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("language-switcher", {
    staticClass: "navbar-nav language-select-container",
    attrs: { tag: "ul", "active-class": "active" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ links }) {
          return _vm._l(links, function (link) {
            return _c(
              "li",
              {
                key: link.langIndex,
                staticClass: "nav-item language-select",
                class: link.activeClass,
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: {
                      href: link.url,
                      "data-href": _vm.$router.resolve(link.url).href,
                      title: _vm.$t(
                        "components.common.language_choices." + link.langIndex
                      ),
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "img-wrap",
                        attrs: { "aria-hidden": "true" },
                      },
                      [
                        _c("b-img", {
                          staticClass: "active",
                          attrs: {
                            src: "/img/flag_" + link.langIndex + ".svg",
                            fluid: "",
                            alt: link.langIndex,
                          },
                        }),
                        _vm._v(" "),
                        _c("b-img", {
                          staticClass: "inactive",
                          attrs: {
                            src:
                              "/img/flag_" + link.langIndex + "_inactive.svg",
                            fluid: "",
                            alt: link.langIndex,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v(_vm._s(link.langIndex)),
                    ]),
                  ]
                ),
              ]
            )
          })
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }