<template>
  <b-nav-dropdown menu-class="menu dropdown-user-actions" :class="{'has-profile-image': !!profileImage}" right variant="text" :no-caret="true">
    <template v-slot:button-content>
      <div class="has-text-icon">
        <div class="top-nav-has-new-messages" :class="{'bg-image': !!profileImage, 'icon-circle bg-info': !profileImage}" :title="$tc('user.menu.new_messages', messageCount)" v-if="messageCount">
          <div class="nav-profile-image" v-if="profileImage"><div :style="{'background-image': `url(${profileImage})`}" class="ba-image-fill"></div></div>
          <font-awesome-icon icon="user" v-else/>
        </div>
        <div :class="{'bg-image': !!profileImage, 'icon-circle bg-info': !profileImage}" v-else>
          <div class="nav-profile-image" v-if="profileImage"><div :style="{'background-image': `url(${profileImage})`}" class="ba-image-fill"></div></div>
          <font-awesome-icon icon="user" v-else/>
        </div>
        <div class="user-menu-info">
          {{ activeAccount.firstName }} {{ activeAccount.lastName }}
          <font-awesome-icon class="icon" icon="chevron-down"/>
        </div>
      </div>
    </template>
    <b-dropdown-item :disabled="true" v-if="isCompanyAccount">
      <span class="text-ellipsis">{{ activeAccount.displayName }}</span>
    </b-dropdown-item>
    <b-dropdown-item :to="appLocalizeRoute({ name: 'dashboard' })">
      <span class="icon-wrap"><font-awesome-icon icon="tachometer-alt"/></span>
      {{ $t('components.navigation.dashboard') }}
    </b-dropdown-item>
    <b-dropdown-item :to="appLocalizeRoute({ name: 'account' })" v-if="isCompanyAccount">
      <span class="icon-wrap"><font-awesome-icon icon="user"/></span>
      {{ $t('components.navigation.account') }}
    </b-dropdown-item>
    <b-dropdown-item :to="appLocalizeRoute({ name: 'company-received-applications' })" v-if="isCompanyAccount">
      <span class="icon-wrap"><font-awesome-icon icon="id-card"/></span>
      {{ $t('components.navigation.company.applications') }}
    </b-dropdown-item>
    <b-dropdown-item :to="appLocalizeRoute({ name: 'bookmarks' })" v-if="isCompanyAccount">
      <span class="icon-wrap"><font-awesome-icon icon="bookmark"/></span>
      {{ $t('components.navigation.bookmarks') }}
    </b-dropdown-item>
    <b-dropdown-item :to="appLocalizeRoute({ name: 'messages' })">
      <span class="icon-wrap has-new-messages" :title="$tc('user.menu.new_messages', messageCount)" v-if="messageCount">
        <font-awesome-icon icon="envelope"/>
      </span>
      <span class="icon-wrap" v-else>
        <font-awesome-icon icon="envelope"/>
      </span>
      {{ $t('components.navigation.messages') }}
    </b-dropdown-item>
    <!--
    <b-dropdown-item :to="appLocalizeRoute({ name: 'applicantsearchprofiles' })" v-if="isCompanyAccount">
      <span class="icon-wrap"><font-awesome-icon icon="search"/></span>
      {{ $t('components.navigation.search_profiles') }}
    </b-dropdown-item>
    -->
    <b-dropdown-item :to="appLocalizeRoute({ name: 'main-applicant-profile' })" v-if="isApplicantAccount && !applicantSettings.allowMultipleApplications">
      <span class="icon-wrap"><font-awesome-icon icon="address-card"/></span>
      {{ $t('components.navigation.profile_applicant') }}
    </b-dropdown-item>
    <b-dropdown-item :to="appLocalizeRoute({ name: 'applicant-list-own-applications' })" v-if="isApplicantAccount && applicantSettings.allowMultipleApplications">
      <span class="icon-wrap"><font-awesome-icon icon="address-card"/></span>
      {{ $t('components.navigation.profiles_applicant') }}
    </b-dropdown-item>
    <b-dropdown-item :to="appLocalizeRoute({ name: 'company-edit-own-profile' })" v-if="isGrantedCompanyProfile">
      <span class="icon-wrap"><font-awesome-icon icon="user"/></span>
      {{ $t('components.navigation.profile_company') }}
    </b-dropdown-item>
    <b-dropdown-item :to="appLocalizeRoute({ name: 'bookmarks' })" v-if="!isCompanyAccount">
      <span class="icon-wrap"><font-awesome-icon icon="bookmark"/></span>
      {{ $t('components.navigation.bookmarks') }}
    </b-dropdown-item>
    <!--
    <b-dropdown-item :to="appLocalizeRoute({ name: 'companyprofiles' })" v-if="isApplicantAccount">
      <span class="icon-wrap"><font-awesome-icon icon="search"/></span>
      {{ $t('components.navigation.search_profiles') }}
    </b-dropdown-item>
    -->
    <b-dropdown-item :href="backendUrl" v-if="isAdminAccount">
      <span class="icon-wrap"><font-awesome-icon icon="user-cog"/></span>
      {{ $t('components.navigation.backend') }}
    </b-dropdown-item>
    <b-dropdown-item :to="appLocalizeRoute({ name: 'account' })" v-if="!isCompanyAccount">
      <span class="icon-wrap"><font-awesome-icon icon="user"/></span>
      {{ $t('components.navigation.account') }}
    </b-dropdown-item>
    <b-dropdown-item :href="backendUrlContactsCompany" @click="onBackendClick" v-if="isAdminAccount">
      <span class="icon-wrap"><font-awesome-icon icon="info-circle"/></span>
      {{ $t('components.navigation.contacts_company') }}
    </b-dropdown-item>
    <b-dropdown-item :to="appLocalizeRoute({ name: 'company-profile-contacts', params: { companyId: activeAccount.companyId } })" v-else-if="isCompanyAccount">
      <span class="icon-wrap"><font-awesome-icon icon="id-badge"/></span>
      {{ $t('components.navigation.company.contacts') }}
    </b-dropdown-item >
    <b-dropdown-item :to="appLocalizeRoute({ name: 'contact' })">
      <span class="icon-wrap"><font-awesome-icon icon="info-circle"/></span>
      {{ $t('components.navigation.contact') }}
    </b-dropdown-item>
  </b-nav-dropdown>
</template>

<script>
  import {mapGetters} from 'vuex';
  import CompanyPermissionsMixin from '@/mixins/companyPermissions';

  export default {
    mixins: [CompanyPermissionsMixin],
    props: {
      messageCount: {
        type: Number,
        required: false,
        default: 0,
      },
      profileImage: {
        type: [Boolean, String],
        required: false,
        default: false,
      }
    },
    computed: {
      ...mapGetters(['activeAccount', 'isCompanyAccount', 'isApplicantAccount', 'isAdminAccount', 'applicantSettings']),
      backendUrl: function () {
        const apiUrl = process.env.API_URL ? process.env.API_URL : '';
        return apiUrl.replace(new RegExp('/api$'), '/admin');
      },
      backendUrlContactsCompany: function () {
        return this.backendUrl + '/brainappeal/campusmatchcompany/company/list';
      },
    },
    methods: {
      onBackendClick: function () {
        window.open(this.backendUrl, '_blank').focus();
      },
    },
  };
</script>
<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  .dropdown-user-actions {
    background: $ba-light-primary;
    font-size: $ba-font-size-sm;
    text-transform: uppercase;
    color: #fff;
    padding: 5px 0 2px 0;
    width: 100%;
    .dropdown-item {
      display: block;
      background: transparent;
      color: $ba-link-color-light;
      padding: 6px 10px 7px;
      transition: color .2s linear;
      border-left: 2px solid $ba-light-primary;
      border-right: 2px solid $ba-light-primary;
      &:not(.disabled) {
        background: $ba-light-blue;
      }
      @include hover-focus {
        color: #fff;
        background: transparent;
      }
    }
    svg {
      display: inline-block;
      margin-right: 14px;
    }
    .icon-wrap {
      display: inline-block;
      width: 20px;
      svg {
        margin-right: 0;
      }
    }
    .has-new-messages {
      @include ba-circle-bg($ba-red, 6px, 1px, 10px);
    }
  }
  .top-nav-has-new-messages {
    @include ba-circle-bg($ba-red, 6px, 0, -2px);
  }
  @include media-breakpoint-up(lg) {
    .topbar-menu-container > .nav-item.has-profile-image > .dropdown-toggle,
    .topbar-menu-container > .nav-item.has-profile-image.show > .dropdown-toggle {
      padding: 0 1.5rem 0 0;
    }
  }
  .bg-image {
    background: transparent;
    &.top-nav-has-new-messages {
      &::before {
        top: auto;
        right: 12px;
        bottom: 4px;
      }
    }
    .nav-profile-image {
      margin-right: 10px;
    }
  }
  .user-menu-info {
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      .icon {
        margin-left: 12px;
        margin-top: -4px;
      }
    }
  }
  .text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  }
</style>

<i18n src="@/translations/navigation.i18n.yaml" lang="yaml"/>
