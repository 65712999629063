const isMobileMixin = {
  data: () => ({
    currentViewport: null,
  }),
  mounted () {
    this.onWindowResize();
    // Returns a function, that, as long as it continues to be invoked, will not
    // be triggered. The function will be called after it stops being called for
    // N milliseconds. If `immediate` is passed, trigger the function on the
    // leading edge, instead of the trailing.
    window.addEventListener('resize', this.debounce(() => {
      this.onWindowResize();
    }, 25));
  },
  onBeforeDestroy () {
    window.removeEventListener('resize', this.onWindowResize);
  },
  methods: {
    debounce: function (func, wait, immediate) {
      let timeout;
      return function () {
        const context = this;
        const args = arguments;
        const later = function () {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    },
    onWindowResize () {
      this.$root.isMobile = this.checkWindowWidth();
      this.currentViewport = this.getViewport();
    },
    checkWindowWidth () {
      return window.innerWidth < this.getBreakpoint('xl') || false;
    },
    isViewport (viewport) {
      return viewport === this.getViewport();
    },
    getViewport () {
      const windowWidth = window.innerWidth;
      const viewports = ['xs', 'sm', 'md', 'lg', 'xl'];
      for (let i = 0, n = viewports.length; i < n; i++) {
        if (windowWidth >= this.getBreakpoint(viewports[i])) {
          const nextLayout = i < n - 1 ? viewports[i + 1] : null;
          if (!nextLayout || windowWidth < this.getBreakpoint(nextLayout)) {
            return viewports[i];
          }
        }
      }
      return 'xs';
    },
    getBreakpoint (viewport) {
      let breakpoint = null;
      switch (viewport) {
        case 'xs':
          breakpoint = 0;
          break;
        case 'sm':
          breakpoint = 576;
          break;
        case 'md':
          breakpoint = 768;
          break;
        case 'lg':
          breakpoint = 992;
          break;
        case 'xl':
          breakpoint = 1260;
          break;
      }
      return breakpoint;
    },
  },
};
export default isMobileMixin;
