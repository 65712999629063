<template>
  <!-- Right aligned nav items -->
  <b-navbar-nav class="main-nav">
    <b-nav-item class="individual-width" :to="appLocalizeRoute({name: 'apply-initiative'})" :class="{'hide-mobile': isApplicantAccount}" v-if="isApplicantAccount && applicantSettings.allowMultipleApplications">
      <span class="icon-wrap"><font-awesome-icon icon="address-card"/></span>
      {{ $t('components.navigation.create_profile_applicant') }}
    </b-nav-item>
    <b-nav-item :to="appLocalizeRoute({name: 'dashboard'})" :class="{'hide-mobile': !isApplicantAccount}">
      <span class="icon-wrap"><font-awesome-icon icon="tachometer-alt"/></span>
      {{ $t('components.navigation.dashboard') }}
    </b-nav-item>
    <b-nav-item class="individual-width" :to="appLocalizeRoute({ name: 'company-list-own-offers' })" v-if="isGrantedCompanyProfile && !config.company.offersEditableInline">
      <span class="icon-wrap"><font-awesome-icon icon="user-cog"/></span>
      {{ $t('components.navigation.profile_company_offers') }}
    </b-nav-item>
    <b-nav-item class="individual-width" :to="appLocalizeRoute({name: 'companysearch'})" v-if="isApplicantAccount || isAdminAccount">
      <span class="icon-wrap"><font-awesome-icon icon="building"/></span>
      {{ $t('components.navigation.companysearch') }}
    </b-nav-item>
    <b-nav-item class="individual-width" :to="appLocalizeRoute({name: 'offer-search'})" v-if="isApplicantAccount || isAdminAccount">
      <span class="icon-wrap"><font-awesome-icon icon="building"/></span>
      {{ $t('components.navigation.offers') }}
    </b-nav-item>
    <b-nav-item class="individual-width" :to="appLocalizeRoute({name: 'company-received-applications'})" v-if="isCompanyAccount">
      <span class="icon-wrap"><font-awesome-icon icon="id-card"/></span>
      {{ $t('components.navigation.company.applications') }}
    </b-nav-item>
    <b-nav-item class="individual-width" :to="appLocalizeRoute({name: 'register'})" v-if="!isAuthenticated">
      <span class="icon-wrap"><font-awesome-icon icon="user"/></span>
      {{ $t('components.navigation.register') }}
    </b-nav-item>
<!--    <b-nav-item class="individual-width" :to="appLocalizeRoute({name: 'profile'})" v-else-if="isApplicantAccount">-->
<!--      <span class="icon-wrap"><font-awesome-icon icon="user"/>--></span>
<!--      {{ $t('components.navigation.profile') }}-->
<!--    </b-nav-item>-->
    <b-nav-item class="individual-width" :to="appLocalizeRoute({name: applicantSearchRoute})" v-else-if="isCompanyAccount || isAdminAccount">
      <span class="icon-wrap"><font-awesome-icon icon="user"/></span>
      {{ $t('components.navigation.applicantsearch') }}
    </b-nav-item>
    <!--
    <b-nav-item :to="appLocalizeRoute({name: 'studypreparation'})">
      <span class="icon-wrap"><font-awesome-icon icon="book"/></span>
      {{ $t('components.navigation.studypreparation') }}
    </b-nav-item>
    <b-nav-item :href="'https://www.mathx3.de'">
      <span class="icon-wrap"><font-awesome-icon icon="calculator"/></span>
      {{ $t('components.navigation.mathx3') }}
    </b-nav-item>
    -->
  </b-navbar-nav>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Config from '@/services/config';
  import CompanyPermissionsMixin from '@/mixins/companyPermissions';

  export default {
    mixins: [CompanyPermissionsMixin],
    data: () => ({
      config: Config,
    }),
    computed: {
      ...mapGetters(['isAuthenticated', 'isApplicantAccount', 'isCompanyAccount', 'isAdminAccount', 'applicantSettings']),
      applicantSearchRoute: function () {
        return Config.applicant.enableApplicationSearch ? 'application-search' : 'applicantsearch';
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  .main-nav {
    position: relative;
    z-index: 11;
    //padding-top: 18px;
    padding-bottom: 18px;
    .nav-item {
      width: 100%;
      position: relative;
      font-size: 16px;
      font-weight: $ba-font-weight-medium;
      line-height: normal;
      letter-spacing: 1.41px;
      text-transform: uppercase;
      &.hide-mobile {
        display: none;
      }
      svg {
        display: inline-block;
        margin-right: 14px;
      }
      .icon-wrap {
        display: inline-block;
        width: 26px;
        svg {
          margin-right: 0;
        }
      }
      .nav-link {
        display: block;
        padding: 18px 0 18px 0;
        transition: color .2s linear;
        &:hover,
        &:focus {
          color: $primary;
        }
      }
      .router-link-active {
        color: $primary;
      }

      @include media-breakpoint-up(lg) {
        padding-right: 9px;
        padding-left: 9px;
        &.hide-mobile {
          display: block;
        }
        svg,
        .icon-wrap {
          display: none;
        }
        .nav-link {
          height: 52px;
          padding: 9px 3px 0 3px;
          display: block;
          border-bottom: 3px solid $gray-300;
          transition: color .2s linear, border-bottom-color .2s linear;
          &:hover,
          &:focus {
            border-color: $primary;
          }
        }
        .router-link-active {
          border-color: $primary;
        }
      }

      @include media-breakpoint-up(xl) {
        padding-right: 26px;
        padding-left: 26px;
        &::after {
          content: "";
          display: block;
          background: $gray-300;
          height: 38px;
          width: 2px;
          position: absolute;
          top: 0;
          right: -1px;
        }
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
          &::after {
            display: none;
          }
        }
      }
    }

    .individual-width > a {
      width: fit-content;
      white-space: nowrap;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 0;
      margin: 62px -3px 0 0;
    }
    @media print {
      display: none;
    }
  }
</style>

<i18n src="@/translations/navigation.i18n.yaml" lang="yaml"/>
