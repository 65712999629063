import { i18n } from 'vue-lang-router';

export default {
  methods: {
    appLocalizeRoute: function (to) {
      // Only process, if current locale is not the default
      if (i18n.locale !== (process.env.VUE_APP_I18N_LOCALE || 'de')) {
        if (typeof to === 'string') {
          return this.$localizePath(to);
        } else if (typeof to === 'object') {
          var o = JSON.parse(JSON.stringify(to));
          if (typeof to.path === 'string') {
            o.path = this.$localizePath(o.path);
            return o;
          }
          if (typeof to.name === 'string') {
            if (to.params) {
              to.params.locale = i18n.locale;
            } else {
              to.params = { locale: i18n.locale };
            }
            const route = this.$router.resolve(to);
            return this.$localizePath(route.href);
          }
        }
      }
      return to;
    },
    appRoutePush: function (route) {
      this.$router.push(this.appLocalizeRoute(route));
    },
  },
};
