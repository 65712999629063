import Http from '@/services/http';

export function login (userdata) {
  return Http.post('/login', userdata);
}
export function refreshAuthenticationData () {
  return Http.get('/account/refresh');
}
export function logout () {
  return Http.get('/logout');
}
