'use strict';

import defaultState from './staticdata.state';

import {
  getAvailableDocuments,
  getAvailableExpertises,
  getAvailableGrades,
  getAvailablePlaceRadius,
  getAvailableQualifications,
  getAvailableSearchDocuments,
  getAvailableSearchNotificationIntervals,
  getAvailableSearchQualificationCountries,
  getAvailableSubjectAreas,
  getAvailableStudyCourses,
  getAvailableStudyInterests,
  getAvailableStudyYears,
  getAvailableEmailSubscriptions,
  getAvailableUniversities,
  getAvailableCountries,
} from '@/api/system';

import {
  getMessagingSettings,
} from '@/api/messaging';

import {
  getApplicantSettings,
} from '@/api/applicant';

import {
  getCompanySettings,
} from '@/api/company';

const tasks = {
  searchDocuments: {
    stateProperty: 'searchDocuments',
    mutator: 'UPDATE_SEARCH_DOCUMENTS',
    apiMethod: getAvailableSearchDocuments,
    lazy: false,
  },
  searchQualifications: {
    stateProperty: 'searchQualifications',
    mutator: 'UPDATE_SEARCH_QUALIFICATIONS',
    apiMethod: getAvailableQualifications,
    lazy: false,
  },
  searchDistances: {
    stateProperty: 'searchDistances',
    mutator: 'UPDATE_SEARCH_DISTANCES',
    apiMethod: getAvailablePlaceRadius,
    lazy: false,
  },
  searchGrades: {
    stateProperty: 'searchGrades',
    mutator: 'UPDATE_SEARCH_GRADES',
    apiMethod: getAvailableGrades,
    lazy: false,
  },
  studyYears: {
    stateProperty: 'studyYears',
    mutator: 'UPDATE_STUDY_YEARS',
    apiMethod: getAvailableStudyYears,
    lazy: false,
  },
  subjectAreas: {
    stateProperty: 'subjectAreas',
    mutator: 'UPDATE_SUBJECT_AREAS',
    apiMethod: getAvailableSubjectAreas,
    lazy: false,
  },
  studyCourses: {
    stateProperty: 'studyCourses',
    mutator: 'UPDATE_STUDY_COURSES',
    apiMethod: getAvailableStudyCourses,
    lazy: false,
  },
  studyInterests: {
    stateProperty: 'studyInterests',
    mutator: 'UPDATE_STUDY_INTERESTS',
    apiMethod: getAvailableStudyInterests,
    lazy: false,
  },
  expertises: {
    stateProperty: 'expertises',
    mutator: 'UPDATE_EXPERTISES',
    apiMethod: getAvailableExpertises,
    lazy: true,
  },
  searchQualificationCountries: {
    stateProperty: 'searchQualificationCountries',
    mutator: 'UPDATE_SEARCH_QUALIFICATION_COUNTRIES',
    apiMethod: getAvailableSearchQualificationCountries,
    lazy: false,
  },
  searchNotificationIntervals: {
    stateProperty: 'searchNotificationIntervals',
    mutator: 'UPDATE_SEARCH_NOTIFICATION_INTERVALS',
    apiMethod: getAvailableSearchNotificationIntervals,
    lazy: false,
  },
  documents: {
    stateProperty: 'documents',
    mutator: 'UPDATE_DOCUMENTS',
    apiMethod: getAvailableDocuments,
    lazy: true,
  },
  emailSubscriptions: {
    stateProperty: 'emailSubscriptions',
    mutator: 'UPDATE_EMAIL_SUBSCRIPTIONS',
    apiMethod: getAvailableEmailSubscriptions,
    lazy: true,
  },
  messagingSettings: {
    stateProperty: 'messagingSettings',
    mutator: 'UPDATE_MESSAGING_SETTINGS',
    apiMethod: getMessagingSettings,
    lazy: true,
  },
  applicantSettings: {
    stateProperty: 'applicantSettings',
    mutator: 'UPDATE_APPLICANT_SETTINGS',
    apiMethod: getApplicantSettings,
    lazy: true,
  },
  companySettings: {
    stateProperty: 'companySettings',
    mutator: 'UPDATE_COMPANY_SETTINGS',
    apiMethod: getCompanySettings,
    lazy: false,
  },
  universities: {
    stateProperty: 'universities',
    mutator: 'UPDATE_UNIVERSITIES',
    apiMethod: getAvailableUniversities,
    lazy: false,
  },
  countries: {
    stateProperty: 'countries',
    mutator: 'UPDATE_COUNTRIES',
    apiMethod: getAvailableCountries,
    lazy: false,
  },
};

const actions = {
  updateSingleStaticData ({ state, commit }, payload) {
    if (Object.prototype.hasOwnProperty.call(tasks, payload)) {
      const { stateProperty, mutator, apiMethod } = tasks[payload];

      if (state[stateProperty] !== undefined) {
        return apiMethod()
          .then(({ data }) => {
            commit(mutator, data);
          })
        ;
      }
    }
  },
  updateStaticData ({ state, commit }) {
    const now = Math.round(Date.now() / 1000);
    const dataOutdated = state.lastUpdate === undefined || state.lastUpdate < (now - 3600);
    if (dataOutdated) {
      commit('UPDATE_LAST_UPDATE', now);

      const promises = [];
      const lazyTasks = [];

      for (const key in tasks) {
        if (Object.prototype.hasOwnProperty.call(tasks, key)) {
          const { stateProperty, mutator, apiMethod, lazy } = tasks[key];

          if (lazy) {
            lazyTasks.push(tasks[key]);
          } else if (state[stateProperty] !== undefined) {
            const promise = apiMethod()
              .then(({ data }) => {
                commit(mutator, data);
              })
            ;
            promises.push(promise);
          }
        }
      }

      const resultPromise = Promise.all(promises);

      if (lazyTasks.length > 0) {
        const lazyPromises = [];
        resultPromise.then(() => {
          for (const { stateProperty, mutator, apiMethod } of lazyTasks) {
            if (state[stateProperty] !== undefined) {
              const promise = apiMethod()
                .then(({ data }) => {
                  commit(mutator, data);
                })
              ;
              lazyPromises.push(promise);
            }
          }

          return Promise.all(lazyPromises);
        });
      }

      return resultPromise;
    }
  },
  forceUpdateStaticData ({ dispatch, commit }) {
    commit('UPDATE_LAST_UPDATE', 0);

    return dispatch('updateStaticData');
  },
};

const mutations = {
  UPDATE_LAST_UPDATE (state, data) {
    state.lastUpdate = (undefined === data) ? defaultState.lastUpdate : data;
  },
  UPDATE_SEARCH_DOCUMENTS (state, data) {
    state.searchDocuments = (undefined === data) ? defaultState.searchDocuments : data;
  },
  UPDATE_SEARCH_QUALIFICATIONS (state, data) {
    state.searchQualifications = (undefined === data) ? defaultState.searchQualifications : data;
  },
  UPDATE_SEARCH_DISTANCES (state, data) {
    state.searchDistances = (undefined === data) ? defaultState.searchDistances : data;
  },
  UPDATE_SEARCH_GRADES (state, data) {
    state.searchGrades = (undefined === data) ? defaultState.searchGrades : data;
  },
  UPDATE_STUDY_YEARS (state, data) {
    state.studyYears = (undefined === data) ? defaultState.studyYears : data;
  },
  UPDATE_SUBJECT_AREAS (state, data) {
    state.subjectAreas = (undefined === data) ? defaultState.subjectAreas : data;
  },
  UPDATE_STUDY_COURSES (state, data) {
    state.studyCourses = (undefined === data) ? defaultState.studyCourses : data;
  },
  UPDATE_STUDY_INTERESTS (state, data) {
    state.studyInterests = (undefined === data) ? defaultState.studyInterests : data;
  },
  UPDATE_EXPERTISES (state, data) {
    state.expertises = (undefined === data) ? defaultState.expertises : data;
  },
  UPDATE_SEARCH_QUALIFICATION_COUNTRIES (state, data) {
    state.searchQualificationCountries = (undefined === data) ? defaultState.searchQualificationCountries : data;
  },
  UPDATE_SEARCH_NOTIFICATION_INTERVALS (state, data) {
    state.searchNotificationIntervals = (undefined === data) ? defaultState.searchNotificationIntervals : data;
  },
  UPDATE_DOCUMENTS (state, data) {
    state.documents = (undefined === data) ? defaultState.documents : data;
  },
  UPDATE_EMAIL_SUBSCRIPTIONS (state, data) {
    state.emailSubscriptions = (undefined === data) ? defaultState.emailSubscriptions : data;
  },
  UPDATE_MESSAGING_SETTINGS (state, data) {
    state.messagingSettings = (undefined === data) ? defaultState.messagingSettings : data;
  },
  UPDATE_APPLICANT_SETTINGS (state, data) {
    state.applicantSettings = (undefined === data) ? defaultState.applicantSettings : data;
  },
  UPDATE_COMPANY_SETTINGS (state, data) {
    state.companySettings = (undefined === data) ? defaultState.companySettings : data;
  },
  UPDATE_UNIVERSITIES (state, data) {
    state.universities = (undefined === data) ? defaultState.universities : data;
  },
  UPDATE_COUNTRIES (state, data) {
    state.countries = (undefined === data) ? defaultState.countries : data;
  },
};

const getters = {
  availableSearchDocuments: function (state) {
    return Object.assign({}, state.searchDocuments ? state.searchDocuments : defaultState.searchDocuments);
  },
  availableQualifications: function (state) {
    return state.searchQualifications ? state.searchQualifications : defaultState.searchQualifications;
  },
  availableDistances: function (state) {
    return state.searchDistances ? state.searchDistances : defaultState.searchDistances;
  },
  availableGrades: function (state) {
    return state.searchGrades ? state.searchGrades : defaultState.searchGrades;
  },
  availableStudyYears: function (state) {
    return state.studyYears ? state.studyYears : defaultState.studyYears;
  },
  availableSubjectAreas: function (state) {
    return state.subjectAreas ? state.subjectAreas : defaultState.subjectAreas;
  },
  availableStudyCourses: function (state) {
    return state.studyCourses ? state.studyCourses : defaultState.studyCourses;
  },
  availableStudyInterests: function (state) {
    return state.studyInterests ? state.studyInterests : defaultState.studyInterests;
  },
  availableExpertises: function (state) {
    return state.expertises ? state.expertises : defaultState.expertises;
  },
  availableSearchQualificationCountries: function (state) {
    return state.searchQualificationCountries ? state.searchQualificationCountries : defaultState.searchQualificationCountries;
  },
  availableSearchNotificationIntervals: function (state) {
    return state.searchNotificationIntervals ? state.searchNotificationIntervals : defaultState.searchNotificationIntervals;
  },
  availableDocuments: function (state) {
    return Object.assign({}, state.documents ? state.documents : defaultState.documents);
  },
  availableEmailSubscriptions: function (state) {
    return Object.assign({}, state.emailSubscriptions ? state.emailSubscriptions : defaultState.emailSubscriptions);
  },
  messagingSettings: function (state) {
    return Object.assign({}, state.messagingSettings ? state.messagingSettings : defaultState.messagingSettings);
  },
  applicantSettings: function (state) {
    return Object.assign({}, state.applicantSettings ? state.applicantSettings : defaultState.applicantSettings);
  },
  companySettings: function (state) {
    return Object.assign({}, state.companySettings ? state.companySettings : defaultState.companySettings);
  },
  availableUniversities: function (state) {
    return Object.assign({}, state.universities ? state.universities : defaultState.universities);
  },
  availableCountries: function (state) {
    return Object.assign({}, state.countries ? state.countries : defaultState.countries);
  },
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
};
