var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.isAuthenticated
    ? _c(
        "b-navbar-nav",
        { staticClass: "topbar-menu-container" },
        [
          _c("ba-navbar-menu", {
            attrs: {
              "message-count": _vm.messageCount,
              "profile-image": _vm.profileImage,
            },
          }),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "nav-item" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.logout()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("components.navigation.action_logout")))]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "b-navbar-nav",
        { staticClass: "topbar-menu-container no-auth" },
        [
          _c(
            "b-nav-dropdown",
            {
              ref: "login-dropdown",
              attrs: { right: "", "no-caret": true },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function () {
                    return [
                      _vm._v(
                        _vm._s(_vm.$t("components.navigation.action_login"))
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm._v(" "),
              _c("ba-login-form", {
                attrs: { id: "nav-login-form-desktop" },
                on: { close: _vm.closeLoginDropdown },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "nav-item" },
            [
              _c(
                "b-link",
                {
                  staticClass: "btn btn-primary",
                  attrs: { to: _vm.appLocalizeRoute({ name: "register" }) },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("components.navigation.action_register"))
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }