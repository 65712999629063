var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-nav-dropdown",
    {
      class: { "has-profile-image": !!_vm.profileImage },
      attrs: {
        "menu-class": "menu dropdown-user-actions",
        right: "",
        variant: "text",
        "no-caret": true,
      },
      scopedSlots: _vm._u([
        {
          key: "button-content",
          fn: function () {
            return [
              _c("div", { staticClass: "has-text-icon" }, [
                _vm.messageCount
                  ? _c(
                      "div",
                      {
                        staticClass: "top-nav-has-new-messages",
                        class: {
                          "bg-image": !!_vm.profileImage,
                          "icon-circle bg-info": !_vm.profileImage,
                        },
                        attrs: {
                          title: _vm.$tc(
                            "user.menu.new_messages",
                            _vm.messageCount
                          ),
                        },
                      },
                      [
                        _vm.profileImage
                          ? _c("div", { staticClass: "nav-profile-image" }, [
                              _c("div", {
                                staticClass: "ba-image-fill",
                                style: {
                                  "background-image": `url(${_vm.profileImage})`,
                                },
                              }),
                            ])
                          : _c("font-awesome-icon", {
                              attrs: { icon: "user" },
                            }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        class: {
                          "bg-image": !!_vm.profileImage,
                          "icon-circle bg-info": !_vm.profileImage,
                        },
                      },
                      [
                        _vm.profileImage
                          ? _c("div", { staticClass: "nav-profile-image" }, [
                              _c("div", {
                                staticClass: "ba-image-fill",
                                style: {
                                  "background-image": `url(${_vm.profileImage})`,
                                },
                              }),
                            ])
                          : _c("font-awesome-icon", {
                              attrs: { icon: "user" },
                            }),
                      ],
                      1
                    ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "user-menu-info" },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.activeAccount.firstName) +
                        " " +
                        _vm._s(_vm.activeAccount.lastName) +
                        "\n        "
                    ),
                    _c("font-awesome-icon", {
                      staticClass: "icon",
                      attrs: { icon: "chevron-down" },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm.isCompanyAccount
        ? _c("b-dropdown-item", { attrs: { disabled: true } }, [
            _c("span", { staticClass: "text-ellipsis" }, [
              _vm._v(_vm._s(_vm.activeAccount.displayName)),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: _vm.appLocalizeRoute({ name: "dashboard" }) } },
        [
          _c(
            "span",
            { staticClass: "icon-wrap" },
            [_c("font-awesome-icon", { attrs: { icon: "tachometer-alt" } })],
            1
          ),
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("components.navigation.dashboard")) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isCompanyAccount
        ? _c(
            "b-dropdown-item",
            { attrs: { to: _vm.appLocalizeRoute({ name: "account" }) } },
            [
              _c(
                "span",
                { staticClass: "icon-wrap" },
                [_c("font-awesome-icon", { attrs: { icon: "user" } })],
                1
              ),
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("components.navigation.account")) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isCompanyAccount
        ? _c(
            "b-dropdown-item",
            {
              attrs: {
                to: _vm.appLocalizeRoute({
                  name: "company-received-applications",
                }),
              },
            },
            [
              _c(
                "span",
                { staticClass: "icon-wrap" },
                [_c("font-awesome-icon", { attrs: { icon: "id-card" } })],
                1
              ),
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("components.navigation.company.applications")) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isCompanyAccount
        ? _c(
            "b-dropdown-item",
            { attrs: { to: _vm.appLocalizeRoute({ name: "bookmarks" }) } },
            [
              _c(
                "span",
                { staticClass: "icon-wrap" },
                [_c("font-awesome-icon", { attrs: { icon: "bookmark" } })],
                1
              ),
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("components.navigation.bookmarks")) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: _vm.appLocalizeRoute({ name: "messages" }) } },
        [
          _vm.messageCount
            ? _c(
                "span",
                {
                  staticClass: "icon-wrap has-new-messages",
                  attrs: {
                    title: _vm.$tc("user.menu.new_messages", _vm.messageCount),
                  },
                },
                [_c("font-awesome-icon", { attrs: { icon: "envelope" } })],
                1
              )
            : _c(
                "span",
                { staticClass: "icon-wrap" },
                [_c("font-awesome-icon", { attrs: { icon: "envelope" } })],
                1
              ),
          _vm._v(
            "\n    " + _vm._s(_vm.$t("components.navigation.messages")) + "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isApplicantAccount && !_vm.applicantSettings.allowMultipleApplications
        ? _c(
            "b-dropdown-item",
            {
              attrs: {
                to: _vm.appLocalizeRoute({ name: "main-applicant-profile" }),
              },
            },
            [
              _c(
                "span",
                { staticClass: "icon-wrap" },
                [_c("font-awesome-icon", { attrs: { icon: "address-card" } })],
                1
              ),
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("components.navigation.profile_applicant")) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isApplicantAccount && _vm.applicantSettings.allowMultipleApplications
        ? _c(
            "b-dropdown-item",
            {
              attrs: {
                to: _vm.appLocalizeRoute({
                  name: "applicant-list-own-applications",
                }),
              },
            },
            [
              _c(
                "span",
                { staticClass: "icon-wrap" },
                [_c("font-awesome-icon", { attrs: { icon: "address-card" } })],
                1
              ),
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("components.navigation.profiles_applicant")) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isGrantedCompanyProfile
        ? _c(
            "b-dropdown-item",
            {
              attrs: {
                to: _vm.appLocalizeRoute({ name: "company-edit-own-profile" }),
              },
            },
            [
              _c(
                "span",
                { staticClass: "icon-wrap" },
                [_c("font-awesome-icon", { attrs: { icon: "user" } })],
                1
              ),
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("components.navigation.profile_company")) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isCompanyAccount
        ? _c(
            "b-dropdown-item",
            { attrs: { to: _vm.appLocalizeRoute({ name: "bookmarks" }) } },
            [
              _c(
                "span",
                { staticClass: "icon-wrap" },
                [_c("font-awesome-icon", { attrs: { icon: "bookmark" } })],
                1
              ),
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("components.navigation.bookmarks")) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isAdminAccount
        ? _c("b-dropdown-item", { attrs: { href: _vm.backendUrl } }, [
            _c(
              "span",
              { staticClass: "icon-wrap" },
              [_c("font-awesome-icon", { attrs: { icon: "user-cog" } })],
              1
            ),
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("components.navigation.backend")) +
                "\n  "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isCompanyAccount
        ? _c(
            "b-dropdown-item",
            { attrs: { to: _vm.appLocalizeRoute({ name: "account" }) } },
            [
              _c(
                "span",
                { staticClass: "icon-wrap" },
                [_c("font-awesome-icon", { attrs: { icon: "user" } })],
                1
              ),
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("components.navigation.account")) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isAdminAccount
        ? _c(
            "b-dropdown-item",
            {
              attrs: { href: _vm.backendUrlContactsCompany },
              on: { click: _vm.onBackendClick },
            },
            [
              _c(
                "span",
                { staticClass: "icon-wrap" },
                [_c("font-awesome-icon", { attrs: { icon: "info-circle" } })],
                1
              ),
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("components.navigation.contacts_company")) +
                  "\n  "
              ),
            ]
          )
        : _vm.isCompanyAccount
        ? _c(
            "b-dropdown-item",
            {
              attrs: {
                to: _vm.appLocalizeRoute({
                  name: "company-profile-contacts",
                  params: { companyId: _vm.activeAccount.companyId },
                }),
              },
            },
            [
              _c(
                "span",
                { staticClass: "icon-wrap" },
                [_c("font-awesome-icon", { attrs: { icon: "id-badge" } })],
                1
              ),
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("components.navigation.company.contacts")) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: _vm.appLocalizeRoute({ name: "contact" }) } },
        [
          _c(
            "span",
            { staticClass: "icon-wrap" },
            [_c("font-awesome-icon", { attrs: { icon: "info-circle" } })],
            1
          ),
          _vm._v(
            "\n    " + _vm._s(_vm.$t("components.navigation.contact")) + "\n  "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }