import Http from '@/services/http';

export function getThreads () {
  return Http.get('/messaging/threads');
}

export function getMessagingSettings () {
  return Http.get('/messaging/settings');
}

export function getThread (threadId) {
  return Http.get(`/messaging/thread/${threadId}`);
}

export function deleteThread (threadId) {
  return Http.delete(`/messaging/thread/${threadId}`);
}

export function archiveThread (threadId, archive = true) {
  return Http.post(`/messaging/archive/${threadId}`, archive);
}

export function markThreadAsRead (threadId) {
  return Http.post(`/messaging/read/${threadId}`, null);
}

export function sendMessage (recipientId, data) {
  return Http.post(`/messaging/message/send/${recipientId}`, data);
}

export function respondMessage (data) {
  return Http.post('/messaging/message/respond', data);
}

export function addMessageAttachment (messageId, file) {
  const data = new FormData();
  if (file) {
    data.append('file', file, file.name);
  }
  return Http.postMultipart('/messaging/message/attach/' + messageId, data);
}

export function getMessageAttachment (id, fileName) {
  return Http.download('/messaging/attachment/' + id, fileName);
}
