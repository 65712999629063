import { mapGetters, mapActions } from 'vuex';
import permissions from '@/services/permissions';

export default {
  computed: {
    ...mapGetters(['activeAccount', 'isCompanyAccount', 'isGranted', 'hasPermissions']),
    permissions: () => permissions,
    isGrantedCompanyProfile: function () {
      if (this.isCompanyAccount) {
        return this.isGranted(permissions.COMPANY_EDIT_PROFILE);
      }
      return false;
    },
  },
  created: function () {
    const self = this;
    if (!this.hasPermissions) {
      self.refresh();
    }
  },
  methods: {
    ...mapActions(['refresh']),
  },
};
