<template>
  <b-navbar-nav class="topbar-menu-container" v-if="this.isAuthenticated">
    <ba-navbar-menu :message-count="messageCount" :profile-image="profileImage"/>
    <li class="nav-item">
      <b-button variant="primary" @click="logout()">{{ $t('components.navigation.action_logout') }}</b-button>
    </li>
  </b-navbar-nav>
  <b-navbar-nav class="topbar-menu-container no-auth" v-else>
    <b-nav-dropdown right :no-caret="true" ref="login-dropdown">
      <template v-slot:button-content>{{ $t('components.navigation.action_login') }}</template>
      <ba-login-form id="nav-login-form-desktop" @close="closeLoginDropdown"/>
    </b-nav-dropdown>
    <li class="nav-item">
      <b-link class="btn btn-primary" :to="appLocalizeRoute({ name: 'register' })">{{ $t('components.navigation.action_register') }}</b-link>
    </li>
  </b-navbar-nav>
</template>

<script>
  import BaLoginForm from '@/components/partials/Header/BaLoginForm';
  import BaNavbarMenu from '@/components/partials/Header/BaNavbarMenu';

  import { mapActions, mapGetters } from 'vuex';

  export default {
    components: {
      BaLoginForm,
      BaNavbarMenu,
    },
    props: {
      messageCount: {
        type: Number,
        required: false,
        default: 0,
      },
      profileImage: {
        type: [Boolean, String],
        required: false,
        default: false,
      }
    },
    computed: {
      ...mapGetters(['isAuthenticated', 'activeAccount']),
    },
    methods: {
      ...mapActions(['logout']),
      closeLoginDropdown: function () {
        this.$refs['login-dropdown'].visible = false;
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  .topbar-menu-container {
    --topbar-nav-link-bg: #{$ba-light-primary};
    display: none;
    max-width: 70%;
    flex-direction: row;
    justify-content: right;
    &.no-auth {
      display: flex;
      flex-wrap: wrap;
      flex: 1 1 auto;
      max-width: 100%;
      //margin-right: -.5rem;
    }

    .dropdown-menu-right {
      margin-top: 0;
      @include media-breakpoint-up(sm) {
        margin-bottom: 10px;
      }
      @include media-breakpoint-up(md) {
        position: absolute;
        margin-bottom: 0;
      }
      @include media-breakpoint-up(lg) {
        margin-top: -3px;

      }
    }

    > .nav-item {
      &:not(:first-child) {
        margin-left: 21px;
      }
      > .nav-link {
        font-size: $ba-font-size-sm;
        letter-spacing: .9px;
        text-transform: uppercase;
        padding-left: 9px;
        padding-right: 9px;
        padding-bottom: 9px;
        line-height: 21px;
        text-align: center;
        @include hover-focus {
          background: var(--topbar-nav-link-bg);
        }
      }
      &.show {
        > .nav-link {
          background: var(--topbar-nav-link-bg);
          letter-spacing: .9px;
          text-transform: uppercase;
          padding-left: 9px;
          padding-right: 9px;
          padding-bottom: 9px;
          line-height: 21px;
        }
      }
    }

    @include media-breakpoint-down(md) {
      > .nav-item {
        > .btn {
          font-size: $ba-font-size-sm;
          border-radius: 0;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: nowrap;
      --topbar-nav-link-bg: #{$ba-bg-primary};
      // compensate padding of last nav item
      //margin-right: -.5rem;
      &.no-auth {
        flex: 0 1 auto;
        max-width: 100%;
        margin-right: 0;
      }
      > .nav-item {
        > .dropdown-toggle {
          display: inline-block;
          font-family: $btn-font-family;
          color: $body-color;
          text-align: center;
          text-decoration: if($link-decoration == none, null, none);
          white-space: $btn-white-space;
          vertical-align: middle;
          user-select: none;
          border: $btn-border-width solid transparent;
          @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
          @include transition($btn-transition);
          text-transform: uppercase;
          border-radius: $btn-border-radius;
          font-weight: $ba-font-weight-medium;
          padding: 0.8125rem 1.5rem .625rem 1.5rem;
          background-color: $info;
          text-decoration: none;
          @include hover {
            color: #fff;
            text-decoration: none;
            //border-color: $primary;//$info;
          }
          &[aria-expanded="true"] {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        &.show {
          > .dropdown-toggle {
            background: var(--topbar-nav-link-bg);
            letter-spacing: .9px;
            padding: 0.8125rem 1.5rem .625rem 1.5rem;
            line-height: 1.25;
          }
        }
      }
    }
  }
  .b-nav-dropdown {
    text-align: center;
  }
</style>

<i18n src="@/translations/navigation.i18n.yaml" lang="yaml"/>
