import permissions from '@/services/permissions';

function load (component) {
  // '@' is aliased to src/components
  return () => import(`@/views/${component}.vue`);
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: load('Home'),
    meta: {
      public: true,
    },
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: load('Onboarding'),
    meta: {
      public: false,
      only_for: 'applicant',
    },
  },
  {
    path: '/passwort-vergessen',
    name: 'password-request',
    component: load('PasswordRequest'),
    meta: {
      public: true,
    },
  },
  {
    path: '/passwort-vergessen/email',
    name: 'password-recovery-email',
    component: load('PasswordRequestEmail'),
    meta: {
      public: true,
    },
  },
  {
    path: '/passwort-vergessen/erneuern/:token?',
    name: 'password-recovery',
    component: load('PasswordRecovery'),
    meta: {
      public: true,
    },
  },
  {
    path: '/passwort-vergessen/erfolgreich',
    name: 'password-recovery-success',
    component: load('PasswordRecoverySuccess'),
    meta: {
      public: true,
    },
  },
  {
    path: '/account-geloescht/:accountType?',
    name: 'account-deleted',
    component: load('AccountDeleted'),
    meta: {
      public: true,
    },
  },
  {
    path: '/account',
    name: 'account',
    component: load('Account'),
    meta: {
      public: false,
    },
  },
  {
    path: '/bewerbungsprofil/:applicationId?',
    name: 'application-profile',
    component: load('EditOwnApplicationProfile'),
    meta: {
      public: false,
      only_for: 'applicant',
    },
  },
  {
    path: '/bewerbungen/vorlage',
    name: 'main-applicant-profile',
    component: load('ManageApplicationTemplate'),
    meta: {
      public: false,
      only_for: 'applicant',
    },
  },
  {
    path: '/bewerben/initiativbewerbung',
    name: 'apply-initiative',
    component: load('ApplyInitiative'),
    meta: {
      public: false,
      only_for: 'applicant',
    },
  },
  {
    path: '/bewerben/:recordId?',
    name: 'apply-for-offer',
    component: load('ApplyForOffer'),
    meta: {
      public: false,
      only_for: 'applicant',
    },
  },
  {
    path: '/bewerben/unternehmen/:recordId?',
    name: 'apply-to-company',
    component: load('ApplyToCompany'),
    meta: {
      public: false,
      only_for: 'applicant',
    },
  },
  {
    path: '/bewerbungsprofile',
    name: 'applicant-list-own-applications',
    component: load('ApplicantListOwnApplications'),
    meta: {
      public: false,
      only_for: 'applicant',
    },
  },
  {
    path: '/bewerber',
    name: 'applicantsearch',
    component: load('ApplicantSearch'),
    meta: {
      public: false,
      only_for: 'company,admin',
    },
  },
  {
    path: '/bewerber/suche/:searchId?',
    name: 'applicantsearchprofile',
    component: load('ApplicantSearch'),
    meta: {
      public: false,
      only_for: 'company,admin',
    },
  },
  {
    path: '/bewerber/suchauftraege',
    name: 'applicantsearchprofiles',
    component: load('ApplicantSearchProfiles'),
    meta: {
      public: false,
      only_for: 'company',
    },
  },
  {
    path: '/bewerber/profil/:applicantId',
    name: 'applicantprofile',
    component: load('ApplicantProfile'),
    meta: {
      public: false,
      only_for: 'company,admin',
    },
  },
  {
    path: '/bewerbungen/:applicationId',
    name: 'applicationprofile',
    component: load('ApplicationProfile'),
    meta: {
      public: false,
      only_for: 'company,admin',
    },
  },
  {
    path: '/favoriten/bewerber',
    name: 'bookmarksapplicant',
    component: load('ApplicantBookmarks'),
    meta: {
      public: false,
      only_for: 'company,admin',
    },
  },
  {
    path: '/unternehmenssuche',
    name: 'companysearch',
    component: load('CompanySearch'),
    meta: {
      public: true,
    },
  },
  {
    path: '/unternehmenskontakte',
    name: 'company-contact-search',
    component: load('CompanyContactSearch'),
    meta: {
      public: false,
    },
  },
  {
    path: '/unternehmensprofil',
    name: 'company-edit-own-profile',
    component: load('EditOwnCompanyProfile'),
    meta: {
      public: false,
      only_for: 'company',
      permission: permissions.COMPANY_EDIT_PROFILE,
    },
  },
  {
    path: '/unternehmensprofil/angebote',
    name: 'company-list-own-offers',
    component: load('CompanyListOwnOffers'),
    meta: {
      public: false,
      only_for: 'company',
      permission: permissions.COMPANY_EDIT_PROFILE,
    },
  },
  {
    path: '/unternehmensprofil/bewerbungen',
    name: 'company-received-applications',
    component: load('CompanyReceivedApplications'),
    meta: {
      public: false,
      only_for: 'company',
      permission: permissions.COMPANY_EDIT_PROFILE,
    },
  },
  {
    path: '/unternehmensprofil/:companyId/kontakte',
    name: 'company-profile-contacts',
    component: load('CompanyProfileContactList'),
    meta: {
      public: false,
    },
  },
  {
    path: '/praktikums-boerse',
    name: 'offer-search',
    component: load('OfferSearch'),
    meta: {
      public: false,
    },
  },
  {
    path: '/bewerber/profile',
    name: 'application-search',
    component: load('ApplicationSearch'),
    meta: {
      public: false,
      only_for: 'company,admin',
    },
  },
  {
    path: '/unternehmensprofil/angebot/erstellen',
    name: 'company-create-offer',
    component: load('CompanyCreateOffer'),
    meta: {
      public: false,
      only_for: 'company',
      permission: permissions.COMPANY_EDIT_PROFILE,
    },
  },
  {
    path: '/unternehmensprofil/angebot/:offerId?',
    name: 'company-edit-own-offer',
    component: load('EditOwnCompanyOffer'),
    meta: {
      public: false,
      only_for: 'company',
      permission: permissions.COMPANY_EDIT_PROFILE,
    },
  },
  {
    path: '/unternehmen/angebot/:offerId?',
    name: 'offer',
    component: load('Offer'),
    meta: {
      public: true,
    },
  },
  {
    path: '/unternehmen/suche/:searchId?',
    name: 'companysearchprofile',
    component: load('CompanySearch'),
    meta: {
      public: true,
    },
  },
  // {
  //   path: '/unternehmen/suchprofile',
  //   name: 'companyprofiles',
  //   component: load('CompanySearchProfiles'),
  //   meta: {
  //     public: true,
  //   },
  // },
  {
    path: '/unternehmen/profil/:companyId',
    name: 'companyprofile',
    component: load('CompanyProfile'),
    meta: {
      public: true,
    },
  },
  {
    path: '/unternehmen/profil/:companyId/contacts/:contactId',
    name: 'organisation-person-profile',
    component: load('CompanyContactProfile'),
    meta: {
      public: false,
    },
  },
  {
    path: '/favoriten/:type?/:page?',
    name: 'bookmarks',
    component: load('Bookmarks'),
    meta: {
      public: false,
    },
  },
  {
    path: '/nachrichten',
    name: 'messages',
    component: load('Messages'),
    meta: {
      public: false,
    },
  },
  {
    path: '/nachrichten/:threadId',
    name: 'message-open',
    component: load('Messages'),
    meta: {
      public: false,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: load('Dashboard'),
    meta: {
      public: false,
    },
  },
  {
    path: '/registrierung/:type?',
    name: 'register',
    component: load('Register'),
    meta: {
      public: true,
    },
  },
  {
    path: '/registrierung/unternehmen/:key',
    name: 'register-company-by-key',
    component: load('RegisterCompanyByKey'),
    meta: {
      public: true,
    },
  },
  {
    path: '/registrierung-verifikation/:token?',
    name: 'register-verification',
    component: load('RegisterVerification'),
    meta: {
      public: true,
    },
  },
  {
    path: '/signup-confirmed',
    name: 'signup-confirmed',
    component: load('SignupSuccessConfirmed'),
    meta: {
      public: true,
    },
  },
  {
    path: '/studiengaenge',
    name: 'studyprograms',
    component: load('StudyPrograms'),
    meta: {
      public: true,
    },
  },
  {
    path: '/studienvorbereitung',
    name: 'studypreparation',
    component: load('StudyPreparation'),
    meta: {
      public: true,
    },
  },
  {
    path: '/mathx3',
    name: 'mathx',
    component: load('MathX'),
  },
  {
    path: '/kontakt',
    name: 'contact',
    component: load('Contact'),
    meta: {
      public: true,
    },
  },
  {
    path: '/kontakt/standort/:locationId',
    name: 'contact-dynamic',
    component: load('Contact'),
    meta: {
      public: true,
    },
  },
  {
    path: '/dhbw-kontakte',
    name: 'support-contact-search',
    component: load('SupportContactSearch'),
    meta: {
      public: false,
    },
  },
  {
    path: '/kontakt/support/:contactId',
    name: 'support-contact-profile',
    component: load('SupportContactProfile'),
    meta: {
      public: true,
    },
  },
  {
    path: '/impressum',
    name: 'impress',
    component: load('static/ImpressPage'),
    meta: {
      public: true,
    },
  },
  {
    path: '/datenschutz',
    name: 'privacy',
    component: load('static/PrivacyPage'),
    meta: {
      public: true,
    },
  },
  {
    path: '/nutzungsbedingungen',
    name: 'agb',
    component: load('static/BusinessTermsAndConditionsPage'),
    meta: {
      public: true,
    },
  },
  {
    path: '/barrierefreiheit',
    name: 'accessibility',
    component: load('static/AccessibilityPage'),
    meta: {
      public: true,
    },
  },
  {
    path: '/faq',
    name: 'faq',
    component: load('FAQ'),
    meta: {
      public: true,
    },
  },
  {
    path: '/faq/thema/:questionId',
    name: 'faq-dynamic',
    component: load('FAQ'),
    meta: {
      public: true,
    },
  },
  {
    path: '/faq-unternehmen',
    name: 'faq-company',
    component: load('FAQCompany'),
    meta: {
      public: true,
    },
  },
  {
    path: '/faq-unternehmen/thema/:questionId',
    name: 'faq-company-dynamic',
    component: load('FAQCompany'),
    meta: {
      public: true,
    },
  },
  {
    path: '*',
    name: 'notfound',
    component: load('NotFound'),
    meta: {
      public: true,
    },
  },
];

export default routes;
