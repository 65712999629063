var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-navbar-nav",
    { staticClass: "main-nav" },
    [
      _vm.isApplicantAccount && _vm.applicantSettings.allowMultipleApplications
        ? _c(
            "b-nav-item",
            {
              staticClass: "individual-width",
              class: { "hide-mobile": _vm.isApplicantAccount },
              attrs: { to: _vm.appLocalizeRoute({ name: "apply-initiative" }) },
            },
            [
              _c(
                "span",
                { staticClass: "icon-wrap" },
                [_c("font-awesome-icon", { attrs: { icon: "address-card" } })],
                1
              ),
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t("components.navigation.create_profile_applicant")
                  ) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-nav-item",
        {
          class: { "hide-mobile": !_vm.isApplicantAccount },
          attrs: { to: _vm.appLocalizeRoute({ name: "dashboard" }) },
        },
        [
          _c(
            "span",
            { staticClass: "icon-wrap" },
            [_c("font-awesome-icon", { attrs: { icon: "tachometer-alt" } })],
            1
          ),
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("components.navigation.dashboard")) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isGrantedCompanyProfile && !_vm.config.company.offersEditableInline
        ? _c(
            "b-nav-item",
            {
              staticClass: "individual-width",
              attrs: {
                to: _vm.appLocalizeRoute({ name: "company-list-own-offers" }),
              },
            },
            [
              _c(
                "span",
                { staticClass: "icon-wrap" },
                [_c("font-awesome-icon", { attrs: { icon: "user-cog" } })],
                1
              ),
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t("components.navigation.profile_company_offers")
                  ) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isApplicantAccount || _vm.isAdminAccount
        ? _c(
            "b-nav-item",
            {
              staticClass: "individual-width",
              attrs: { to: _vm.appLocalizeRoute({ name: "companysearch" }) },
            },
            [
              _c(
                "span",
                { staticClass: "icon-wrap" },
                [_c("font-awesome-icon", { attrs: { icon: "building" } })],
                1
              ),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("components.navigation.companysearch")) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isApplicantAccount || _vm.isAdminAccount
        ? _c(
            "b-nav-item",
            {
              staticClass: "individual-width",
              attrs: { to: _vm.appLocalizeRoute({ name: "offer-search" }) },
            },
            [
              _c(
                "span",
                { staticClass: "icon-wrap" },
                [_c("font-awesome-icon", { attrs: { icon: "building" } })],
                1
              ),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("components.navigation.offers")) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isCompanyAccount
        ? _c(
            "b-nav-item",
            {
              staticClass: "individual-width",
              attrs: {
                to: _vm.appLocalizeRoute({
                  name: "company-received-applications",
                }),
              },
            },
            [
              _c(
                "span",
                { staticClass: "icon-wrap" },
                [_c("font-awesome-icon", { attrs: { icon: "id-card" } })],
                1
              ),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("components.navigation.company.applications")) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isAuthenticated
        ? _c(
            "b-nav-item",
            {
              staticClass: "individual-width",
              attrs: { to: _vm.appLocalizeRoute({ name: "register" }) },
            },
            [
              _c(
                "span",
                { staticClass: "icon-wrap" },
                [_c("font-awesome-icon", { attrs: { icon: "user" } })],
                1
              ),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("components.navigation.register")) +
                  "\n    "
              ),
            ]
          )
        : _vm.isCompanyAccount || _vm.isAdminAccount
        ? _c(
            "b-nav-item",
            {
              staticClass: "individual-width",
              attrs: {
                to: _vm.appLocalizeRoute({ name: _vm.applicantSearchRoute }),
              },
            },
            [
              _c(
                "span",
                { staticClass: "icon-wrap" },
                [_c("font-awesome-icon", { attrs: { icon: "user" } })],
                1
              ),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("components.navigation.applicantsearch")) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }