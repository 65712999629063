'use strict';

import state from './auth.state';
import router from '@/router';
import { login, logout, refreshAuthenticationData } from '@/api/security';
import { i18n } from 'vue-lang-router';

const actions = {
  login ({ commit, dispatch }, userData) {
    return login(userData)
      .then(function ({ data: response }) {
        commit('SET_TOKEN', response);
        router.push(i18n.locale === 'en' ? '/en/dashboard' : { name: 'dashboard' });
        dispatch('updateProfile');
      });
    ;
    // .catch(dispatch('logout'))
  },
  refresh ({ commit, dispatch }) {
    return refreshAuthenticationData()
      .then(function ({ data: response }) {
        commit('SET_TOKEN', response);
        //dispatch('updateProfile');
      });
    ;
  },
  loginByToken ({ commit, dispatch }, token) {
    commit('SET_TOKEN', token);
    dispatch('updateProfile');
  },
  logout ({ commit }, redirectRoute) {
    commit('SET_TOKEN', { token: '' });
    commit('UPDATE_USER', { type: '' });
    logout()
      .finally(function () {
        if (redirectRoute) {
          router.push(redirectRoute);
        } else if (!router.currentRoute.meta.public) {
          router.push(i18n.locale === 'en' ? '/en/' : { name: 'home' });
        }
      })
    ;
  },
  logoutIfAuthenticated ({ dispatch, state }) {
    if (state.token.token.length > 0) {
      dispatch('logout');
    }
  },
};

const mutations = {
  SET_TOKEN (state, token = {}) {
    state.token.token = token.token ? token.token : '';
    state.token.security = token.security ? token.security : '';
  },
};

const getters = {
  isAuthenticated: function (state) {
    return state.token.token.length > 0;
  },
  hasPermissions: function (state) {
    const permissions = state.token.security && state.token.security.length > 0 ? JSON.parse(atob(state.token.security)) : [];
    return permissions.length > 0;
  },
  isGranted: function (state, getters) {
    const permissions = state.token.security && state.token.security.length > 0 ? JSON.parse(atob(state.token.security)) : [];

    return (permission) => {
      if (!getters.isAuthenticated) {
        return false;
      }

      return permissions.includes(permission);
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
