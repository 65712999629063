'use strict';

import Http from '@/services/http';

if (Http.isMockingEnabled()) {
  require('@/api/mock/security.mock');
  require('@/api/mock/account.mock');
  require('@/api/mock/messaging.mock');
  require('@/api/mock/applicant.mock');
  require('@/api/mock/company.mock');
  require('@/api/mock/statistics.mock');
}
