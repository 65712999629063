<template>
  <b-form inline @submit.prevent="checkUserLogin(form)" class="login-form-container" :id="id">
    <div class="login-form-close" @click="emitClose"><font-awesome-icon icon="times"/></div>
    <div class="container">
      <h6 class="login-label">{{ $t('components.partials.header.login_form.headline') }}</h6>
      <div class="form-group" :class="{'has-error':errors.username}">
        <b-form-input
          :id="context + 'login'"
          v-model="form.username"
          :placeholder="$t('components.partials.header.login_form.email')"
          type="email"
          required
          trim
        ></b-form-input>
        <div class="invalid-feedback">{{ $t('components.partials.header.login_form.email.feedback') }}</div>
      </div>
      <div class="form-group" :class="{'has-error':errors.password}">
        <ba-form-password
          :id="context + 'password'"
          v-model="form.password"
          :placeholder="$t('components.partials.header.login_form.password')"
          required
        />
        <div class="invalid-feedback">
          <span v-if="errors.passwordInvalid">{{ $t('components.partials.header.login_form.feedback_general') }}</span>
          <span v-else>{{ $t('components.partials.header.login_form.password.feedback') }}</span>
        </div>
      </div>
      <div class="action-container">
        <b-button class="login-button" variant="primary" type="submit">
          {{ $t('components.partials.header.login_form.action.login') }}
        </b-button>
        <b-link class="lost-password" :to="appLocalizeRoute({ name: 'password-request' })" @click="emitClose">
          {{ $t('components.partials.header.login_form.action.forgot_password') }}
        </b-link>
        <b-link class="signup-link" :to="appLocalizeRoute({ name: 'register' })" @click="emitClose">
          {{ $t('components.partials.header.login_form.action.sign_up') }}
        </b-link>
      </div>
    </div>
  </b-form>
</template>

<script>
  import BaFormPassword from '@/components/partials/Form/BaFormPassword';
  import { mapActions } from 'vuex';

  export default {
    components: {
      BaFormPassword,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
      context: {
        type: String,
        default: '',
      },
    },
    data: () => ({
      form: {
        username: '',
        password: '',
      },
      errors: {
        username: false,
        password: false,
        passwordInvalid: false,
      },
    }),
    mounted: function () {
      const formElement = document.getElementById(this.id);
      if (formElement) {
        const requiredFields = formElement.querySelectorAll('[required]');
        for (let i = 0; i < requiredFields.length; i++) {
          requiredFields[i].removeAttribute('required');
          requiredFields[i].setAttribute('placeholder', requiredFields[i].getAttribute('placeholder') + ' *');
          requiredFields[i].classList.add('validate-required');
        }
      }
    },
    methods: {
      ...mapActions(['login']),
      checkUserLogin: function (form) {
        if (this.validateForm()) {
          form.username = form.username.trim();
          form.password = form.password.trim();
          this.login(form)
            .then(() => {
              this.$store.dispatch('forceUpdateStaticData');
              this.$store.dispatch('resetSearchStorage');
            })
            .catch(error => {
              if (!error.status || error.status !== 'GENERIC_ERROR') {
                this.errors.username = true;
              }
              this.errors.password = true;
              this.errors.passwordInvalid = true;
            })
          ;
        }
      },
      validateForm: function () {
        let formIsValid = true;
        this.errors.username = false;
        this.errors.password = false;
        this.errors.passwordInvalid = false;
        if (!this.form.username || !this.validEmail(this.form.username)) {
          this.errors.username = true;
          formIsValid = false;
        }
        if (!this.form.password) {
          this.errors.password = true;
          formIsValid = false;
        }
        return formIsValid;
      },
      validEmail: function (email) {
        const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regularExpression.test(email);
      },
      emitClose: function () {
        this.$emit('close');
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  $login-close-size: 30px;

  .login-form-container {
    background: $gray-100;
    padding: 1rem .25rem;
    position: relative;
    .login-form-close {
      cursor: pointer;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
      text-align: center;
      position: absolute;
      right: 10px;
      top: 10px;
      width: $login-close-size;
      height: $login-close-size;
      transition: color .2s ease-in-out;
    }
    .container {
      max-width: 350px;
    }
    .login-label {
      color: $ba-dark-grey-blue;
      margin: 0.5rem auto 1rem auto;
      font-size: $font-size-base;
      font-weight: $ba-font-weight-medium;
      line-height: $line-height-base;
      @media (min-width: 1200px) {
        display: none;
      }
    }
    .form-group {
      display: block;
      margin-bottom: 4px;
      width: 100%;
    }
    .form-control {
      margin-bottom: 0;
      width: 100%;
    }
    .input-group {
      width: 100%;
      flex-wrap: nowrap;
      .form-control {
        width: auto;
      }
      .input-group-append {
        background-color: #fff;
      }
    }
    .action-container {
      width: 100%;
    }
    .login-button {
      display: block;
      width: 100%;
      max-width: 245px;
      margin: 19px auto 21px auto;
      padding-top: .6875rem;
      padding-bottom: .6875rem;
      border-radius: 23px;

      @include media-breakpoint-up(lg) {
        width: 180px;
        max-width: 205px;
        margin: 0;
        order: 1;
      }
    }
    .lost-password, .signup-link {
      display: block;
      margin: 1rem auto 26px;
      text-decoration: underline;
      text-align: center;
    }

    @include media-breakpoint-up(lg) {
      background-color: $ba-bg-primary;
      min-width: 384px;
      $login-container-padding-left-lg: 11px;
      padding: 22px $login-container-padding-left-lg 23px 4px;

      .login-form-close {
        color: #fff;
        right: 0;
        top: 0;
        @include hover-focus-active {
          color: $secondary;
        }
      }
      .container {
        max-width: 100%;
      }
      .signup-link {
        display: none;
      }
      .login-label {
        display: none;
      }
      .form-group {
        display: block;
        margin-bottom: 17px;
        padding-right: $login-close-size - 15px - $login-container-padding-left-lg;
      }
      .action-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .lost-password {
        color: #fff;
        line-height: 2.3;
        margin: 0;
        text-decoration: underline;
        order: 0;
        white-space: nowrap;

        @include hover {
          text-decoration: none;
        }
      }
    }
  }
</style>

<i18n src="@/translations/common.i18n.yaml" lang="yaml"/>

