var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "user-menu" }, [
    _c(
      "div",
      { staticClass: "container nav-container" },
      [
        _c("div", { staticClass: "user-info" }, [
          _vm.profileImage
            ? _c("div", { staticClass: "user-icon nav-profile-image" }, [
                _c("div", {
                  staticClass: "ba-image-fill",
                  style: { "background-image": `url(${_vm.profileImage})` },
                }),
              ])
            : _c(
                "div",
                { staticClass: "user-icon" },
                [
                  _c("font-awesome-icon", {
                    attrs: { size: "2x", icon: "building" },
                  }),
                ],
                1
              ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "user-data" },
            [
              _c("h5", [
                _vm._v(
                  _vm._s(_vm.activeAccount.firstName) +
                    " " +
                    _vm._s(_vm.activeAccount.lastName)
                ),
              ]),
              _vm._v(" "),
              _vm.isCompanyAccount
                ? _c("div", [_vm._v(_vm._s(_vm.activeAccount.displayName))])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-link",
                {
                  on: {
                    click: function ($event) {
                      return _vm.logout()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("components.navigation.action_logout")))]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "b-navbar-nav",
          { staticClass: "user-links" },
          [
            _vm.isApplicantAccount &&
            _vm.applicantSettings.allowMultipleApplications
              ? _c(
                  "b-nav-item",
                  {
                    attrs: {
                      to: _vm.appLocalizeRoute({ name: "apply-initiative" }),
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "icon-wrap" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "address-card" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "components.navigation.create_profile_applicant"
                          )
                        ) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isApplicantAccount
              ? _c(
                  "b-nav-item",
                  {
                    attrs: { to: _vm.appLocalizeRoute({ name: "dashboard" }) },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "icon-wrap" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "tachometer-alt" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("components.navigation.dashboard")) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isCompanyAccount
              ? _c(
                  "b-nav-item",
                  { attrs: { to: _vm.appLocalizeRoute({ name: "account" }) } },
                  [
                    _c(
                      "span",
                      { staticClass: "icon-wrap" },
                      [_c("font-awesome-icon", { attrs: { icon: "user" } })],
                      1
                    ),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("components.navigation.account")) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isCompanyAccount
              ? _c(
                  "b-nav-item",
                  {
                    staticClass: "d-none d-lg-block",
                    attrs: {
                      to: _vm.appLocalizeRoute({
                        name: "company-received-applications",
                      }),
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "icon-wrap" },
                      [_c("font-awesome-icon", { attrs: { icon: "id-card" } })],
                      1
                    ),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("components.navigation.company.applications")
                        ) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isCompanyAccount
              ? _c(
                  "b-nav-item",
                  {
                    attrs: { to: _vm.appLocalizeRoute({ name: "bookmarks" }) },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "icon-wrap" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "bookmark" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("components.navigation.bookmarks")) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "b-nav-item",
              { attrs: { to: _vm.appLocalizeRoute({ name: "messages" }) } },
              [
                _vm.messageCount
                  ? _c(
                      "span",
                      {
                        staticClass: "icon-wrap has-new-messages",
                        attrs: {
                          title: _vm.$tc(
                            "user.menu.new_messages",
                            _vm.messageCount
                          ),
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "envelope" },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "span",
                      { staticClass: "icon-wrap" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "envelope" },
                        }),
                      ],
                      1
                    ),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("components.navigation.messages")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _vm.isApplicantAccount &&
            !_vm.applicantSettings.allowMultipleApplications
              ? _c(
                  "b-nav-item",
                  {
                    attrs: {
                      to: _vm.appLocalizeRoute({
                        name: "main-applicant-profile",
                      }),
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "icon-wrap" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "address-card" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("components.navigation.profile_applicant")
                        )
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isApplicantAccount &&
            _vm.applicantSettings.allowMultipleApplications
              ? _c(
                  "b-nav-item",
                  {
                    attrs: {
                      to: _vm.appLocalizeRoute({
                        name: "applicant-list-own-applications",
                      }),
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "icon-wrap" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "address-card" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("components.navigation.profiles_applicant")
                        )
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isGrantedCompanyProfile
              ? _c(
                  "b-nav-item",
                  {
                    attrs: {
                      to: _vm.appLocalizeRoute({
                        name: "company-edit-own-profile",
                      }),
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "icon-wrap" },
                      [_c("font-awesome-icon", { attrs: { icon: "user" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("components.navigation.profile_company"))
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isCompanyAccount
              ? _c(
                  "b-nav-item",
                  {
                    attrs: { to: _vm.appLocalizeRoute({ name: "bookmarks" }) },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "icon-wrap" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "bookmark" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("components.navigation.bookmarks")) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isAdminAccount
              ? _c("b-nav-item", { attrs: { href: _vm.backendUrl } }, [
                  _c(
                    "span",
                    { staticClass: "icon-wrap" },
                    [_c("font-awesome-icon", { attrs: { icon: "user-cog" } })],
                    1
                  ),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("components.navigation.backend")) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.isCompanyAccount
              ? _c(
                  "b-nav-item",
                  { attrs: { to: _vm.appLocalizeRoute({ name: "account" }) } },
                  [
                    _c(
                      "span",
                      { staticClass: "icon-wrap" },
                      [_c("font-awesome-icon", { attrs: { icon: "user" } })],
                      1
                    ),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("components.navigation.account")) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isAdminAccount
              ? _c(
                  "b-nav-item",
                  {
                    attrs: { href: _vm.backendUrlContactsCompany },
                    on: { click: _vm.onBackendClick },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "icon-wrap" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "info-circle" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("components.navigation.contacts_company")
                        ) +
                        "\n      "
                    ),
                  ]
                )
              : _vm.isCompanyAccount
              ? _c(
                  "b-nav-item",
                  {
                    attrs: {
                      to: _vm.appLocalizeRoute({
                        name: "company-profile-contacts",
                        params: { companyId: _vm.activeAccount.companyId },
                      }),
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "icon-wrap" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "id-badge" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("components.navigation.company.contacts")
                        ) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "b-nav-item",
              { attrs: { to: _vm.appLocalizeRoute({ name: "contact" }) } },
              [
                _c(
                  "span",
                  { staticClass: "icon-wrap" },
                  [_c("font-awesome-icon", { attrs: { icon: "info-circle" } })],
                  1
                ),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("components.navigation.contact")) +
                    "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }