import Http from '@/services/http';
import { RECORD_TYPE } from '@/api/constants';

function getRecordTypeUrl (recordType, urls) {
  if (urls[recordType]) {
    return urls[recordType];
  }
  throw new Error('Wrong record type set: ' + recordType);
}

// ----------------------------------------------------------------------------

export function fetchRecordList (recordType) {
  const path = getRecordTypeUrl(recordType, {
    [RECORD_TYPE.APPLICATION]: '/applications',
    [RECORD_TYPE.APPLICANT]: '/applicants',
    [RECORD_TYPE.APPLICANT_MANAGEMENT]: '/applicant-management/', // Keep the end slash!
    [RECORD_TYPE.COMPANY]: '/companies',
    [RECORD_TYPE.SEARCH_FOR_COMPANIES]: '/company-search/pattern',
    [RECORD_TYPE.SEARCH_FOR_APPLICANTS]: '/applicant-search/pattern',
    [RECORD_TYPE.OFFER]: '/offers',
  });
  return Http.get(path);
}

export function fetchRecord (recordType, recordId, data) {
  const path = getRecordTypeUrl(recordType, {
    [RECORD_TYPE.APPLICATION]: `/application/${recordId}`,
    [RECORD_TYPE.APPLICANT]: `/applicant/${recordId}`,
    [RECORD_TYPE.APPLICANT_MANAGEMENT]: `/applicant-management/${recordId}`,
    [RECORD_TYPE.COMPANY]: `/company/${recordId}`,
    [RECORD_TYPE.PAGE]: `/cms/page/${recordId}`,
    [RECORD_TYPE.OFFER]: `/offer/${recordId}`,
  });
  return Http.get(path, data);
}
