import Http from '@/services/http';
import { fetchRecord } from '@/api/record';
import { RECORD_TYPE } from '@/api/constants';

export function getOwnApplicantProfile () {
  return Http.get('/applicant-profile');
}

export function setOwnApplicantProfile (profile) {
  return Http.post('/applicant-profile', profile);
}

export function getApplicantManagementList () {
  return Http.get('/applicant-management/');
}
export function getApplicantManagementData (applicantId) {
  return Http.get(`/applicant-management/${applicantId}`);
}
export function saveApplicantManagement (applicantId, data) {
  return Http.post(`/applicant-management/${applicantId}`, data);
}

export function renewOwnApplicantProfile () {
  return Http.get('/applicant-profile/renew');
}

export function setOwnApplicantProfileImage (image, type) {
  const data = new FormData();
  if (image) {
    data.append('image', image, image.name);
    return Http.postMultipart(`/applicant-profile/image/${type}`, data);
  }
  return Http.post(`/applicant-profile/image/${type}`, { image: null });
}

export function setOwnApplicantProfileAttachment (file, type, applicationId) {
  const data = new FormData();
  if (file) {
    data.append('file', file, file.name);
  }
  return Http.postMultipart(`/applicant-profile/file/${applicationId}/${type}`, data);
}

// ----------------------------------------------------------------------------

export function getApplicant (id, enableTracking) {
  const data = enableTracking || typeof enableTracking === 'undefined' ? null : { nt: 1 };
  return fetchRecord(RECORD_TYPE.APPLICANT, id, data);
}

export function getApplicantTeaser (applicantId) {
  return Http.get(`/applicant/teaser/${applicantId}`);
}

const ImageCache = {};

export function getApplicantProfileImage (applicantId) {
  return getApplicantImage(applicantId, 'mainImage');
}

export function getApplicantImage (applicantId, type) {
  const promise = Http.getBinary(`/applicant/${applicantId}/image/${type}`);
  ImageCache[applicantId + '_' + type] = promise;
  return promise;
}
export function getApplicantImageCached (applicantId, type) {
  return ImageCache[applicantId + '_' + type] ? ImageCache[applicantId + '_' + type] : getApplicantImage(applicantId, type);
}

export function getApplicantAttachment (applicantId, applicationId, type, fileName) {
  return Http.download(`/applicant/${applicantId}/${applicationId}/file/${type}`, fileName);
}

export function getApplicantSettings () {
  return Http.get('/applicant-profile/settings');
}
