import Http from '@/services/http';
import { fetchRecord } from '@/api/record';
import { RECORD_TYPE } from '@/api/constants';

export function getOwnCompanyProfile () {
  return Http.get('/company-profile');
}

export function setOwnCompanyProfile (profile) {
  return Http.post('/company-profile', profile);
}

export function getOwnCompanyProfileStudyInterests () {
  return Http.get('/company-profile/available-study-interests');
}
/*
export function downloadCompanyBookmarksPdf (fileName) {
  return Http.download('/company-bookmarks/bookmarks.pdf', fileName);
}
*/

export function setOwnCompanyProfileImage (image, type) {
  const data = new FormData();
  if (image) {
    data.append('image', image, image.name);
    return Http.postMultipart(`/company-profile/image/${type}`, data);
  }
  return Http.post(`/company-profile/image/${type}`, { image: null });
}

export function setOwnCompanyProfileAttachment (file, type) {
  const data = new FormData();
  if (file) {
    data.append('file', file, file.name);
    return Http.postMultipart(`/company-profile/file/${type}`, data);
  }
  return Http.post(`/company-profile/file/${type}`, { file: null });
}
// ----------------------------------------------------------------------------

export function getMatchingCompanies (pattern) {
  return Http.get('/companies/search/' + encodeURIComponent(pattern.trim()));
}

export function getMatchingCompaniesForApplicant (pattern) {
  return Http.get('/companies/by-applicant/search/' + encodeURIComponent(pattern.trim()));
}

export function getCompany (id, enableTracking) {
  const data = enableTracking || typeof enableTracking === 'undefined' ? null : { nt: 1 };
  return fetchRecord(RECORD_TYPE.COMPANY, id, data);
}

export function getCompanyMapMarkers (search) {
  return Http.post('/company-search/map-markers', search);
}

export function getCompanySettings () {
  return Http.get('/company-profile/settings');
}

const ImageCache = {};

export function getCompanyProfileImage (companyId) {
  return getCompanyImage(companyId, 'mainImage');
}
export function getCompanyProfileImageCached (companyId) {
  return getCompanyImageCached(companyId, 'mainImage');
}

export function getCompanyImage (companyId, type) {
  const promise = Http.getBinary(`/company/${companyId}/image/${type}`);
  ImageCache[companyId + '_' + type] = promise;
  return promise;
}
export function getCompanyImageCached (companyId, type) {
  return ImageCache[companyId + '_' + type] ? ImageCache[companyId + '_' + type] : getCompanyImage(companyId, type);
}

export function getCompanyAttachment (companyId, type, fileName) {
  return Http.download(`/company/${companyId}/file/${type}`, fileName);
}
